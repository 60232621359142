import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && navigate && location.pathname === "/app") {
      const token = localStorage.getItem("token");

      if (token) {
        navigate("/app/companies");
      } else {
        navigate("/");
      }
    }
  }, [location, navigate]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default Layout;
