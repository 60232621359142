import PageContainer from "../components/pageContainer/PageContainer";
import { Check, Plus, WorkfullyLogo } from "../assets/icons";
import React, { useState } from "react";
import ApiDatabase from "../server";
import StyledInput from "../components/styledInput/StyledInput";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import Badges from "../components/badges";

const BadgerName = () => {
  const navigate = useNavigate();

  const [createBadgerName, setCreateBadgerName] = useState("");
  const [isLoadingCreateBadgerName, setIsLoadingCreateBadgerName] =
    useState(false);
  const companyId = localStorage.getItem("companyId");
  const badgeNamesExample = [
    { name: "Caisse centrale" },
    { name: "Accueil" },
    { name: "Dépôt" },
  ];

  const handleCreateBadgerName = () => {
    if (createBadgerName) {
      setIsLoadingCreateBadgerName(true);
      ApiDatabase.postCreateBadgerName(
        { companyId, badgerName: createBadgerName },
        (data) => {
          setCreateBadgerName("");
          setIsLoadingCreateBadgerName(false);
          localStorage.setItem("badgerId", data.badgerId);
          localStorage.setItem("badgerName", createBadgerName);
          navigate("/app/badger");
        },
        (err) => {
          console.log(err);
          setIsLoadingCreateBadgerName(false);
        },
      );
    }
  };

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div
          className={
            "w-full max-w-[448px] m-auto border rounded-xl bg-white p-8 flex flex-col gap-6 max-h-full"
          }
        >
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
          <div className={""}>
            <div className={"text-3xl font-semibold"}>
              Où se situe la badgeuse ?
            </div>
            <div className={"text-sm text-gray-600"}>
              Pour mieux identifier votre badgeuse, indiquez le lieu où elle se
              situe dans votre enseigne.
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            <StyledInput
              type={"text"}
              label={"Indiquer le lieu"}
              value={createBadgerName}
              placeholder={"Réception, accueil..."}
              onChange={(e) => {
                setCreateBadgerName(e.target.value);
              }}
            />
            <div className={"flex gap-2"}>
              {badgeNamesExample.map((badgeName, index) => (
                <div
                  className={"w-fit font-bold cursor-pointer"}
                  key={index}
                  onClick={() => setCreateBadgerName(badgeName.name)}
                >
                  <Badges color={"SECONDARY"} size={"SM"}>
                    <div className={"flex justify-between gap-2"}>
                      {badgeName.name}
                      {createBadgerName === badgeName.name ? (
                        <Check wh={16} color={"#374151"} />
                      ) : (
                        <Plus wh={16} color={"#374151"} />
                      )}
                    </div>
                  </Badges>
                </div>
              ))}
            </div>
          </div>
          <div className={"flex gap-1"}>
            <Button
              color={"SECONDARY"}
              size={"FULL"}
              onClick={() => navigate("/app/companies")}
            >
              Annuler
            </Button>
            {createBadgerName ? (
              <>
                {isLoadingCreateBadgerName ? (
                  <Button color={"DISABLED"} size={"FULL"}>
                    Confirmation ...
                  </Button>
                ) : (
                  <Button
                    color={"PRIMARY"}
                    size={"FULL"}
                    onClick={() => handleCreateBadgerName()}
                  >
                    Confirmer
                  </Button>
                )}
              </>
            ) : (
              <Button color={"DISABLED"} size={"FULL"}>
                Confirmer
              </Button>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default BadgerName;
