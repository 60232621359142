import StyledInput from "../styledInput/StyledInput";
import { LoopIcon } from "../../assets/icons";

const { dateLong } = require("../../utils/Date");

const NavbarSiteParameters = ({ search, setSearch }) => {
  return (
    <nav className={"py-4 px-8 border-b border-b-gray-100"}>
      <div className={"flex justify-between items-center"}>
        <div className={"text-2xl font-semibold capitalize"}>
          {dateLong(new Date())}
        </div>
        <div className={"max-w-[330px] w-full"}>
          <StyledInput
            type={"text"}
            placeholder={"Rechercher..."}
            imgFront={<LoopIcon wh={20} color={"#6B7280"} />}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
    </nav>
  );
};

export default NavbarSiteParameters;
