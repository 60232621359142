import Button from "../button";
import {
  ArrowLeft,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Logout,
  WorkfullyLogo,
} from "../../assets/icons";
import Tooltip from "../tooltips/Tooltips";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const SidebarParameters = () => {
  const navigate = useNavigate();
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const logout = () => {
    localStorage.removeItem("companyId");
    localStorage.removeItem("badgerId");
    localStorage.removeItem("companyName");
    localStorage.removeItem("badgerName");
    navigate("/");
  };

  const [sidebarElements, setSidebarElements] = useState([
    {
      title: "Suivi horaire",
      icon: <Calendar wh={20} color={"#374151"} />,
      iconActive: <Calendar wh={20} color={"#D77A1E"} />,
      path: "/app/parameters",
      type: "content",
      isActive: true,
    },
    {
      title: "Retour",
      icon: <ArrowLeft wh={20} color={"#374151"} />,
      iconActive: <ArrowLeft wh={20} color={"#D77A1E"} />,
      path: "/app/badger",
      type: "content",
      isActive: false,
    },
    {
      title: "Déconnexion",
      icon: <Logout wh={20} color={"#374151"} />,
      iconActive: <Logout wh={20} color={"#D77A1E"} />,
      type: "footer",
      isActive: false,
      onClick: logout,
    },
  ]);
  const currentPath = useLocation().pathname;

  useEffect(() => {
    if (currentPath) {
      setSidebarElements((prev) =>
        prev.map((element) => ({
          ...element,
          isActive: currentPath.includes(element.path),
        })),
      );
    }
  }, [currentPath]);

  return (
    <div
      className={`px-5 py-8 flex flex-col h-full ${sidebarExpanded ? "w-64 transition-w-64" : "w-20 transition-w-20"} duration-700 gap-11 border-r border-r-gray-100 bg-gray-50`}
    >
      <div className={"flex justify-between gap-8 items-center"}>
        {sidebarExpanded && (
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
        )}
        <Tooltip
          text={sidebarExpanded ? "Reduire" : "Agrandir"}
          placement="right"
        >
          <div>
            <Button
              color={"ICON"}
              size={"ICON"}
              onClick={() => setSidebarExpanded(!sidebarExpanded)}
            >
              {sidebarExpanded ? (
                <ChevronLeft wh={20} color={"#374151"} />
              ) : (
                <ChevronRight wh={20} color={"#374151"} />
              )}
            </Button>
          </div>
        </Tooltip>
      </div>
      <div className={"flex-1 py-4 text-sm"}>
        {/*only élement with type: "content"*/}
        {sidebarElements
          .filter((element) => element.type === "content")
          .map((element, key) => {
            return (
              <NavLink
                to={element.path}
                key={key}
                className={`w-full flex gap-4 items-center px-2 py-3 cursor-pointer rounded-lg border ${element.isActive ? "border-yellow-100 bg-yellow-50 text-yellow-600" : "border-gray-50"}`}
              >
                <div>
                  {element.isActive ? element.iconActive : element.icon}
                </div>
                {sidebarExpanded && <div>{element.title}</div>}
              </NavLink>
            );
          })}
      </div>
      <div className={"text-sm"}>
        {sidebarElements
          .filter((element) => element.type === "footer")
          .map((element, key) => {
            if (element.onClick) {
              return (
                <div
                  key={key}
                  className={`w-full flex gap-4 items-center px-2 py-3 cursor-pointer rounded-lg border ${element.isActive ? "border-yellow-100 bg-yellow-50 text-yellow-600" : "border-gray-50"}`}
                  onClick={element.onClick}
                >
                  <div>
                    {element.isActive ? element.iconActive : element.icon}
                  </div>
                  {sidebarExpanded && <div>{element.title}</div>}
                </div>
              );
            } else {
              return (
                <NavLink
                  to={element.path}
                  key={key}
                  className={`w-full flex gap-4 items-center px-2 py-3 cursor-pointer rounded-lg border ${element.isActive ? "border-yellow-100 bg-yellow-50 text-yellow-600" : "border-gray-50"}`}
                >
                  <div>
                    {element.isActive ? element.iconActive : element.icon}
                  </div>
                  {sidebarExpanded && <div>{element.title}</div>}
                </NavLink>
              );
            }
          })}
      </div>
    </div>
  );
};

export default SidebarParameters;
