import PageContainer from "../components/pageContainer/PageContainer";
import React, { useEffect, useState } from "react";
import ApiDatabase from "../server";
import { useNavigate, useParams } from "react-router-dom";
import StyledInput from "../components/styledInput/StyledInput";
import Button from "../components/button";
import { WorkfullyLogo } from "../assets/icons";

const Invitation = () => {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const { id } = useParams();

  const tRole = {
    200: "Observateur",
    205: "Manageur",
    210: "Responsable",
    215: "Directeur",
    225: "Administrateur",
  };

  useEffect(() => {
    if (id) {
      setIsWaiting(true);
      localStorage.setItem("token", id);

      ApiDatabase.getEmployeeById(
        {},
        (data) => {
          setIsWaiting(false);
          setEmployee(data.employee);
        },
        (err) => {
          setIsWaiting(false);
          console.log(err);
        },
      );
    }
  }, [id]);

  const handleSubmit = () => {
    if (password && confirmPassword) {
      if (password.length < 8) {
        setPasswordError(
          "Le mot de passe doit contenir au moins 8 caractères.",
        );
      } else {
        setPasswordError(false);
      }
      if (password !== confirmPassword) {
        setConfirmPasswordError("Les mots de passe ne correspondent pas.");
      } else {
        setConfirmPasswordError(false);
      }
      if (password.length >= 8 && password === confirmPassword) {
        setLoading(true);

        ApiDatabase.putPasswordEmployee(
          { password },
          (data) => {
            navigate("/app");
          },
          (err) => {
            setLoading(false);
            console.log(err);
          },
        );
      }
    } else {
      if (!password) setPasswordError("Veuillez entrer un mot de passe.");
      else setPasswordError(false);
      if (!confirmPassword)
        setConfirmPasswordError("Veuillez confirmer votre mot de passe.");
      else setConfirmPasswordError(false);
    }
  };

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div
          className={
            "w-full max-w-[448px] m-auto border rounded-xl bg-white p-8 flex flex-col gap-6 max-h-full"
          }
        >
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
          {isWaiting ? (
            <>
              <div className={"flex flex-col gap-1 animate-pulse"}>
                <div className={"h-8 bg-gray-200 rounded w-full"}></div>
              </div>
              <div className={"text-sm text-gray-600 animate-pulse"}>
                <div className={"h-4 bg-gray-200 rounded w-full"}></div>
                <div className={"h-4 bg-gray-200 rounded w-20 mt-1"}></div>
              </div>
              <div
                className={"h-12 bg-gray-200 rounded w-full animate-pulse"}
              ></div>
              <div
                className={"h-12 bg-gray-200 rounded w-full animate-pulse"}
              ></div>
              <div
                className={"h-12 bg-gray-200 rounded w-full animate-pulse"}
              ></div>
            </>
          ) : (
            <>
              <div className={"flex flex-col gap-1"}>
                <div className={"text-3xl font-semibold"}>
                  Bonjour {employee.firstname} {employee.lastname}
                </div>
              </div>
              <div className={"text-sm text-gray-600"}>
                Vous avez été invité par{" "}
                <span className={"text-gray-700 font-bold"}>
                  {employee.bigCompanyName}
                </span>{" "}
                en tant que {tRole[employee.role]}.
              </div>
              <StyledInput
                label={"Mot de passe"}
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError}
              />
              <StyledInput
                label={"Confirmer le mot de passe"}
                type={"password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={confirmPasswordError}
              />
              {loading ? (
                <Button color={"DISABLED"} size={"FULL"}>
                  Confirmation ...
                </Button>
              ) : (
                <Button color={"PRIMARY"} size={"FULL"} onClick={handleSubmit}>
                  Confirmer
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default Invitation;
