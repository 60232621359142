import PageContainer from "../components/pageContainer/PageContainer";
import React, { useEffect, useState } from "react";
import StyledInput from "../components/styledInput/StyledInput";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import ApiDatabase from "../server";
import { WorkfullyLogo } from "../assets/icons";

const ForgotPasswordChange = () => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/forgot-password");
    }
  }, [token, navigate]);

  const handleSubmit = () => {
    if (password && confirmPassword) {
      if (password.length < 8) {
        setPasswordError(
          "Le mot de passe doit contenir au moins 8 caractères.",
        );
      } else {
        setPasswordError(false);
      }
      if (password !== confirmPassword) {
        setConfirmPasswordError("Les mots de passe ne correspondent pas.");
      } else {
        setConfirmPasswordError(false);
      }
      if (password.length >= 8 && password === confirmPassword) {
        setLoading(true);
        ApiDatabase.postUserLostPasswordChange(
          { password },
          (data) => {
            navigate("/app");
          },
          (err) => {
            console.log(err);
            setLoading(false);
          },
        );
      }
    } else {
      if (!password) setPasswordError("Veuillez entrer un mot de passe.");
      else setPasswordError(false);
      if (!confirmPassword)
        setConfirmPasswordError("Veuillez confirmer votre mot de passe.");
      else setConfirmPasswordError(false);
    }
  };

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div
          className={
            "w-full max-w-[448px] m-auto border rounded-xl bg-white p-8 flex flex-col gap-6 max-h-full"
          }
        >
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
          <div className={"flex flex-col gap-1"}>
            <div className={"text-3xl font-semibold"}>
              Modifier votre mot de passe
            </div>
          </div>
          <StyledInput
            type={"password"}
            label={"Mot de passe"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            error={passwordError}
          />
          <StyledInput
            type={"password"}
            label={"Confirmer le mot de passe"}
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            error={confirmPasswordError}
          />
          {loading ? (
            <Button color={"DISABLED"} size={"FULL"}>
              Confirmation ...
            </Button>
          ) : (
            <Button color={"PRIMARY"} size={"FULL"} onClick={handleSubmit}>
              Confirmer
            </Button>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default ForgotPasswordChange;
