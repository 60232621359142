import TableHourTrackingMission from "../components/tables/TableHourTrackingMission";
import EmptyState from "../components/EmptyState/EmptyState";
import FooterHourlyTracking from "../components/footers/FooterHourlyTracking";
import PageContainerParametersDetail from "../components/pageContainer/PageContainerParametersDetail";
import React, { useEffect, useState } from "react";
import useTitle from "../hooks/TitleManager";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";
import removeAccents from "remove-accents";
import UseContext from "../hooks/useContext";

const HourlyTracking = () => {
  useTitle({ title: "Suivi horaire" });
  const { missions } = UseContext();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const [missionsToDisplay, setMissionsToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 25;
  const [countTotal, setCountTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (missions.length > 0) {
      const missionsByUserAndDate = {};

      missions.forEach((mission) => {
        const key = `${mission.user.idTempWorker}-${new Date(mission.date).toISOString().split("T")[0]}`;
        if (!missionsByUserAndDate[key]) {
          missionsByUserAndDate[key] = [];
        }
        missionsByUserAndDate[key].push(mission);
      });

      const finalMissions = [];

      Object.keys(missionsByUserAndDate).forEach((key) => {
        const missionsToFilter = missionsByUserAndDate[key];
        const hasNonFlexible = missionsToFilter.some(
          (mission) => mission.isFlexibilityDate === false,
        );

        if (hasNonFlexible) {
          missionsToFilter.forEach((mission) => {
            if (mission.isFlexibilityDate === false) {
              finalMissions.push(mission);
            }
          });
        } else {
          finalMissions.push(...missionsToFilter);
        }
      });

      setCountTotal(finalMissions.length);
      setTotalPages(Math.ceil(finalMissions.length / countPerPage));
      const start = (currentPage - 1) * countPerPage;
      const end = start + countPerPage;

      setMissionsToDisplay(finalMissions.slice(start, end));
    } else {
      setMissionsToDisplay([]);
    }
  }, [missions, currentPage, countPerPage, setMissionsToDisplay]);

  const navigateToMission = (idMission) => {
    navigate(`${idMission}`);
  };

  useEffect(() => {
    let filteredMissions = missions;
    if (search.length > 0) {
      const searchTerms = search
        .split(" ")
        .map((term) => removeAccents(term.toLowerCase()));
      const options = {
        keys: [
          "user.firstname",
          "user.lastname",
          "user.phone",
          "user.tokenMission",
          "idMission",
          "companyName",
        ],
        includeScore: true,
        threshold: 0.3,
        distance: 100,
        shouldSort: true,
        getFn: (obj, path) => {
          const value = Fuse.config.getFn(obj, path);
          if (value == null) {
            return "";
          }
          return removeAccents(value.toString().toLowerCase());
        },
      };

      const fuse = new Fuse(missions, options);
      const combinedResults = {};

      searchTerms.forEach((term) => {
        const result = fuse.search(term);
        result.forEach(({ item, score }) => {
          const indexSearch = item._id + item.date + term;
          if (combinedResults[indexSearch]) {
            combinedResults[indexSearch].score -= score;
          } else {
            combinedResults[indexSearch] = { item, score };
          }
        });
      });

      const sortedResults = Object.values(combinedResults).sort(
        (a, b) => a.score - b.score,
      );
      filteredMissions = sortedResults.map((result) => result.item);
    }

    const missionsByUserAndDate = {};

    filteredMissions.forEach((mission) => {
      const key = `${mission.user.idTempWorker}-${new Date(mission.date).toISOString().split("T")[0]}`;
      if (!missionsByUserAndDate[key]) {
        missionsByUserAndDate[key] = [];
      }
      missionsByUserAndDate[key].push(mission);
    });

    const finalMissions = [];

    Object.keys(missionsByUserAndDate).forEach((key) => {
      const missions = missionsByUserAndDate[key];
      const hasNonFlexible = missions.some(
        (mission) => mission.isFlexibilityDate === false,
      );

      if (hasNonFlexible) {
        missions.forEach((mission) => {
          if (mission.isFlexibilityDate === false) {
            finalMissions.push(mission);
          }
        });
      } else {
        finalMissions.push(...missions);
      }
    });

    setMissionsToDisplay(
      finalMissions.slice(
        (currentPage - 1) * countPerPage,
        currentPage * countPerPage,
      ),
    );
    setCountTotal(finalMissions.length);
    setTotalPages(Math.ceil(finalMissions.length / countPerPage));
  }, [search, missions, currentPage, countPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, missions]);

  return (
    <PageContainerParametersDetail
      type={"hourly-tracking"}
      search={search}
      setSearch={setSearch}
    >
      <div className={"flex flex-1 flex-col gap-4 h-full"}>
        <div className={"flex flex-1 flex-col h-full overflow-auto"}>
          <>
            {missionsToDisplay.length > 0 ? (
              <div className={"flex flex-col gap-3 min-w-[830px]"}>
                {missionsToDisplay.map((mission, index) => (
                  <TableHourTrackingMission
                    key={index}
                    mission={mission}
                    navigateToMission={navigateToMission}
                  />
                ))}
              </div>
            ) : (
              <EmptyState type={"hourly_tracking"} />
            )}
          </>
        </div>
        <FooterHourlyTracking
          currentPage={currentPage}
          countPerPage={countPerPage}
          countTotal={countTotal}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </PageContainerParametersDetail>
  );
};

export default HourlyTracking;
