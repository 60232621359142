import PageContainer from "../components/pageContainer/PageContainer";
import { WorkfullyLogoWhite } from "../assets/icons";
import React, { useEffect, useState } from "react";
import NumberBoard from "../components/numberBoard";
import ApiDatabase from "../server";
import { useNavigate } from "react-router-dom";
import useTitle from "../hooks/TitleManager";

const FirstPinAdmin = () => {
  useTitle({ title: "Code Pin Admin" });
  const navigate = useNavigate();
  const [codePinAdmin, setCodePinAdmin] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (codePinAdmin.length === 4) {
      setIsLoading(true);
      ApiDatabase.postCreateAdminPin(
        { adminPin: codePinAdmin },
        (data) => {
          navigate("/app/badger-name");
        },
        (err) => {
          setIsLoading(false);
          console.log(err);
        },
      );
    }
  }, [codePinAdmin, navigate]);

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div className={"flex flex-col gap-8 w-full max-w-[448px] m-auto"}>
          <div className={"flex flex-col text-white"}>
            <div className={""}>Génération d’un code admin</div>
            <div>Pour accéder aux paramètres</div>
          </div>
          <div
            className={
              "border rounded-xl bg-white p-8 flex flex-col gap-6 max-h-full"
            }
          >
            <div className={"flex justify-center"}>
              <NumberBoard
                code={codePinAdmin}
                setCode={setCodePinAdmin}
                nbElements={4}
                isSecret={true}
                isLoading={isLoading}
                size={"MD"}
              />
            </div>
          </div>
          <div className={"mx-auto"}>
            <WorkfullyLogoWhite w={130} h={30} />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default FirstPinAdmin;
