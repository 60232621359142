import {
  CheckRound,
  Cutlery,
  Entry,
  Exit,
  Pause,
  Play,
  WarningCircle,
} from "../assets/icons";
import React, { useEffect, useRef, useState } from "react";
import UseContext from "../hooks/useContext";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import ApiDatabase from "../server";
import useTitle from "../hooks/TitleManager";
import {
  addMinute,
  calculateTotalHoursAndPause,
  durationInDecimals,
} from "../utils/Time";
import PageContainerSiteBadge from "../components/pageContainer/PageContainerSiteBadge";
import { addLog } from "../utils/indexedDB";
import { dateToEpoch } from "../utils/Date";

const badgeSound = new Audio("/badgeSound.mp3");

const ConfirmUserBadger = () => {
  useTitle({ title: "Vérification utilisateur" });
  const {
    dataCodePinConfirmUserBadger,
    setDataCodePinConfirmUserBadger,
    setAllMissionToken,
    setMissions,
  } = UseContext();
  const navigate = useNavigate();
  const [tempWorkerName, setTempWorkerName] = useState("");
  const [period, setPeriod] = useState("");
  const [timeCut, setTimeCut] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    if (
      dataCodePinConfirmUserBadger.firstname &&
      dataCodePinConfirmUserBadger.lastname
    ) {
      setTempWorkerName(
        `${dataCodePinConfirmUserBadger.firstname} ${dataCodePinConfirmUserBadger.lastname}`,
      );
      setPeriod(
        dataCodePinConfirmUserBadger.timeSheet[
          dateToEpoch(new Date()).toISOString().split("T")[0]
        ] || [],
      );
      setTimeCut(dataCodePinConfirmUserBadger.timeCut);
    }
  }, [dataCodePinConfirmUserBadger]);

  const notUser = () => {
    setDataCodePinConfirmUserBadger({});
    navigate("/app/badger");
  };

  const handleSubmit = () => {
    if (
      dataCodePinConfirmUserBadger.tokenMission &&
      dataCodePinConfirmUserBadger.date
    ) {
      let timePart = dataCodePinConfirmUserBadger.date.split(" ")[1];
      if (
        !/^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/.test(
          dataCodePinConfirmUserBadger.date,
        )
      ) {
        return null;
      }
      setIsLoading(true);

      // Générer idHour ici
      const idHour = Math.floor(Math.random() * 1000000).toString();

      const updateAllMissionTokenTimeSheet = (prevData) => {
        const updatedMissions = [];

        for (let i = 0; i < prevData.length; i++) {
          let mission = prevData[i];

          if (
            mission.tokenMission === dataCodePinConfirmUserBadger.tokenMission
          ) {
            const timeSheet =
              mission.timeSheet[
                dateToEpoch(new Date()).toISOString().split("T")[0]
              ] || [];

            if (
              timeSheet.some(
                (entry) =>
                  entry.hours.idStart === idHour ||
                  entry.hours.idEnd === idHour,
              )
            ) {
              // idHour already exists
              updatedMissions.push(mission);
              continue;
            }

            if (timeSheet.length === 0) {
              timeSheet.push({
                hours: {
                  start: timePart,
                  end: "",
                  idStart: idHour,
                  idEnd: "",
                },
                position: 1,
              });

              updatedMissions.push({
                ...mission,
                timeSheet: {
                  [dateToEpoch(new Date()).toISOString().split("T")[0]]:
                    timeSheet,
                },
              });
              continue;
            }

            const lastEntryIndex = timeSheet.length - 1;
            const lastEntry = timeSheet[lastEntryIndex];

            if (
              lastEntry.hours.end === timePart ||
              lastEntry.hours.start === timePart
            ) {
              timePart = addMinute(timePart);
            }

            if (lastEntry.hours.end) {
              timeSheet.push({
                hours: {
                  start: timePart,
                  end: "",
                  idStart: idHour,
                  idEnd: "",
                },
                position: lastEntryIndex + 2,
              });
              updatedMissions.push({
                ...mission,
                timeSheet: {
                  [dateToEpoch(new Date()).toISOString().split("T")[0]]:
                    timeSheet,
                },
              });
            } else {
              timeSheet[lastEntryIndex] = {
                ...lastEntry,
                hours: { ...lastEntry.hours, end: timePart, idEnd: idHour },
              };

              updatedMissions.push({
                ...mission,
                timeSheet: {
                  [dateToEpoch(new Date()).toISOString().split("T")[0]]:
                    timeSheet,
                },
              });
            }
          } else {
            updatedMissions.push(mission);
          }
        }

        return updatedMissions;
      };

      const updateMissions = (prevData) => {
        const updatedMissions = [];

        for (let i = 0; i < prevData.length; i++) {
          let mission = prevData[i];

          if (
            mission.user?.tokenMission ===
            dataCodePinConfirmUserBadger.tokenMission
          ) {
            if (
              dateToEpoch(mission.date).toISOString().split("T")[0] !==
              dateToEpoch(new Date()).toISOString().split("T")[0]
            ) {
              mission = {
                ...mission,
                timeSheets: [],
                date: dateToEpoch(new Date()).toISOString().split("T")[0],
                markup: {
                  start: "",
                  end: "",
                  pause: "",
                },
              };
            }
            const timeSheets = mission.timeSheets || [];

            if (
              timeSheets.some((sheet) =>
                sheet.periods.some(
                  (period) =>
                    period.hours.idStart === idHour ||
                    period.hours.idEnd === idHour,
                ),
              )
            ) {
              updatedMissions.push(mission);
              continue;
            }

            let currentSheet = timeSheets[0];
            if (!currentSheet) {
              currentSheet = {
                date: mission.date,
                idMission: mission.idMission,
                pause: 0,
                totalHours: 0,
                periods: [],
              };
              timeSheets.push(currentSheet);
            }

            const periods = currentSheet.periods || [];
            const lastEntryIndex = periods.length - 1;
            const lastEntry = periods[lastEntryIndex];

            if (lastEntry && lastEntry.hours.end) {
              const newPeriod = {
                hours: {
                  start: timePart,
                  end: "",
                  idStart: idHour,
                  idEnd: "",
                },
                position: lastEntryIndex + 2,
              };
              currentSheet.periods.push(newPeriod);
            } else if (lastEntry) {
              lastEntry.hours.end = timePart;
              lastEntry.hours.idEnd = idHour;
            } else {
              const newPeriod = {
                hours: {
                  start: timePart,
                  end: "",
                  idStart: idHour,
                  idEnd: "",
                },
                position: 1,
              };
              currentSheet.periods.push(newPeriod);
            }

            const { pause, totalHours } = calculateTotalHoursAndPause(
              currentSheet.periods,
              timeCut,
            );
            currentSheet.pause = pause;
            currentSheet.totalHours = totalHours;

            mission = {
              ...mission,
              timeSheets: [currentSheet],
              markup: {
                start: currentSheet.periods[0].hours.start,
                end: currentSheet.periods[currentSheet.periods.length - 1].hours
                  .end,
                pause: currentSheet.pause || "",
              },
            };
          }

          updatedMissions.push(mission);
        }

        return updatedMissions;
      };

      const handleApiSuccess = (data) => {
        try {
          badgeSound
            .play()
            .catch((err) => console.error("Error playing sound:", err));
        } finally {
          setAllMissionToken(updateAllMissionTokenTimeSheet);
          setMissions(updateMissions);
          setDataCodePinConfirmUserBadger({});
          navigate("/app/success-user-badger");
        }
      };

      const handleApiError = (err) => {
        if (
          err.message === "No internet connection - request queued" ||
          err.message ===
            "Request timed out - it has been queued for later execution" ||
          err.message === "No internet access or request timed out" ||
          err.message === "Network Error" ||
          err.message === "Failed to fetch" ||
          err.message === "Network request failed" ||
          err.message === "Connection refused" ||
          err.message === "Request aborted" ||
          err.message === "Internet Disconnected" ||
          err.message === "Unable to resolve the server's DNS address"
        ) {
          try {
            badgeSound
              .play()
              .catch((err) => console.error("Error playing sound:", err));
          } finally {
            setAllMissionToken(updateAllMissionTokenTimeSheet);
            setMissions(updateMissions);
            setDataCodePinConfirmUserBadger({});
            navigate("/app/success-user-badger");
          }
        }
      };

      const logEntry = {
        tokenMission: dataCodePinConfirmUserBadger.tokenMission,
        date: dataCodePinConfirmUserBadger.date,
        idHour: idHour,
        action: "postCodePinBadger",
      };

      addLog(logEntry)
        .then(() => {
          ApiDatabase.postCodePinBadger(
            {
              tokenMission: dataCodePinConfirmUserBadger.tokenMission,
              date: dataCodePinConfirmUserBadger.date,
              idHour: idHour,
            },
            handleApiSuccess,
            handleApiError,
          );
        })
        .catch((err) => {
          console.error("Error adding log:", err);
        });
    }
  };

  useEffect(() => {
    if (!dataCodePinConfirmUserBadger.tokenMission) {
      navigate("/app/badger");
    }
  }, [dataCodePinConfirmUserBadger, navigate]);

  useEffect(() => {
    const setScrollToBottom = () => {
      if (scrollableRef.current) {
        scrollableRef.current.scrollTop = -scrollableRef.current.scrollHeight;
      }
    };

    if (period.length > 0) setScrollToBottom();
  }, [period]);

  return (
    <PageContainerSiteBadge>
      <div>
        <div className={"flex justify-between gap-1"}>
          <div className={"text-2xl"}>Bonjour,</div>
          <div className={"flex justify-end w-[194px]"}>
            <div
              className={
                "text-gray-700 text-lg underline w-fit h-fit cursor-pointer"
              }
              onClick={notUser}
            >
              Ce n'est pas moi
            </div>
          </div>
        </div>
        <div className={""}>
          <div className={"text-3xl font-black"}>{tempWorkerName}</div>
        </div>
      </div>
      {period.length > 0 ? (
        <div
          ref={scrollableRef}
          className={
            "flex flex-col-reverse rounded-lg w-full h-fit overflow-y-auto border text-lg"
          }
        >
          {period.map((markup, index) => {
            const filteredHours = Object.entries(markup.hours).filter(
              ([key, value]) => key === "start" || key === "end",
            );

            return filteredHours.map(([key, value], i) => {
              if (value) {
                const getType = () => {
                  if (key === "start") {
                    if (index === 0) {
                      return {
                        icon: (
                          <div className={"rounded-full bg-blue-500 p-2"}>
                            <Entry wh={25} color={"#FFFFFF"} />
                          </div>
                        ),
                        name: "Entrée",
                      };
                    } else if (
                      period[index - 1]?.hours?.end
                        ? durationInDecimals(
                            period[index - 1].hours.end,
                            value,
                          ) <= timeCut
                        : false
                    ) {
                      return {
                        icon: (
                          <div className={"rounded-full bg-yellow-500 p-2"}>
                            <Cutlery wh={25} color={"#FFFFFF"} />
                          </div>
                        ),
                        name: "Fin de pause",
                      };
                    } else {
                      return {
                        icon: (
                          <div className={"rounded-full bg-yellow-500 p-2"}>
                            <Play wh={25} color={"#FFFFFF"} />
                          </div>
                        ),
                        name: "Fin de coupure",
                      };
                    }
                  } else if (key === "end") {
                    if (index === period.length - 1) {
                      return {
                        icon: (
                          <div className={"rounded-full bg-blue-500 p-2"}>
                            <Exit wh={25} color={"#FFFFFF"} />
                          </div>
                        ),
                        name: "Sortie",
                      };
                    } else if (
                      period[index + 1]?.hours?.start
                        ? durationInDecimals(
                            value,
                            period[index + 1].hours.start,
                          ) <= timeCut
                        : false
                    ) {
                      return {
                        icon: (
                          <div className={"rounded-full bg-yellow-500 p-2"}>
                            <Cutlery wh={25} color={"#FFFFFF"} />
                          </div>
                        ),
                        name: "Début de pause",
                      };
                    } else {
                      return {
                        icon: (
                          <div className={"rounded-full bg-yellow-500 p-2"}>
                            <Pause wh={25} color={"#FFFFFF"} />
                          </div>
                        ),
                        name: "Début de coupure",
                      };
                    }
                  }
                  return { icon: "", name: "" };
                };

                const type = getType();

                return (
                  <div
                    key={i}
                    className={`flex justify-between items-center p-4 ${(i !== 0 || index !== 0) && "border-b"}`}
                  >
                    <div className={"flex gap-6 items-center"}>
                      {type && <div>{type.icon}</div>}
                      {type && <div>{type.name}</div>}
                    </div>
                    <div
                      className={
                        "flex justify-end gap-6 items-center text-lg text-gray-500"
                      }
                    >
                      {value && <div>{value}</div>}
                    </div>
                  </div>
                );
              }
              return null;
            });
          })}
        </div>
      ) : (
        <div className={"flex gap-4 items-center"}>
          <div
            className={
              "p-2 flex justify-center items-center bg-red-50 rounded-full"
            }
          >
            <WarningCircle wh={32} color={"#EF4444"} />
          </div>
          <div className={"text-lg text-gray-500"}>
            <div className={"font-bold"}>Aucun pointage disponible</div>
            <div>Il n'y a pas de pointage pour ce jour</div>
          </div>
        </div>
      )}
      <div className={"flex-1"}></div>
      <div className={"flex flex-col items-center gap-3"}>
        {isLoading ? (
          <Button color={"DISABLED"} size={"FULL"}>
            Confirmation...
          </Button>
        ) : (
          <Button
            color={"PRIMARY"}
            size={"FULL"}
            onClick={() => handleSubmit()}
          >
            <div className={"flex gap-1 items-center text-xl"}>
              Badger ici
              <CheckRound wh={30} color={"#FFFFFF"} />
            </div>
          </Button>
        )}
      </div>
    </PageContainerSiteBadge>
  );
};

export default ConfirmUserBadger;
