import ApiDatabase from "../server";
const versionFile = require("../version.json");

const VersionChecker = async () => {
  const versionLocal = versionFile.version;

  const cacheBurst = localStorage.getItem("cacheBurst");
  if (cacheBurst !== versionLocal) {
    caches.keys().then((names) => {
      for (let name of names) {
        caches.delete(name);
      }
    });
    localStorage.setItem("cacheBurst", versionLocal);

    window.location.href =
      window.location.origin +
      window.location.pathname +
      "?cache-bust=" +
      new Date().getTime();
  }

  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Request timed out")), 30000),
  );

  try {
    const data = await Promise.race([
      new Promise((resolve, reject) => {
        ApiDatabase.getLatestVersion(
          { type: "app" },
          (data) => resolve(data),
          (error) => reject(error),
        );
      }),
      timeout,
    ]);

    const versionServer = data.version;
    const needsUpdate = versionLocal !== versionServer;
    return { versionLocal, versionServer, needsUpdate };
  } catch (error) {
    console.error(error);
    return { versionLocal, versionServer: null, needsUpdate: false };
  }
};

export default VersionChecker;
