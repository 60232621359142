import PageContainerParameters from "../components/pageContainer/PageContainerParameters";
import NumberBoard from "../components/numberBoard";
import React, { useEffect, useState } from "react";
import useTitle from "../hooks/TitleManager";
import { useNavigate } from "react-router-dom";
import UseContext from "../hooks/useContext";

const Parameters = () => {
  useTitle({ title: "Paramètres" });
  const navigate = useNavigate();
  const { adminPinCode } = UseContext();
  const [codePin, setCodePin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorCodePin, setErrorCodePin] = useState(false);

  useEffect(() => {
    if (codePin.length === 4) {
      setIsLoading(true);
      if (adminPinCode?.toString() === codePin?.toString()) {
        setIsLoading(false);
        setErrorCodePin(false);
        setCodePin("");
        navigate("hourly-tracking");
      } else {
        setIsLoading(false);
        setErrorCodePin(true);
        setCodePin("");
      }
    }
  }, [codePin, navigate, adminPinCode]);

  return (
    <PageContainerParameters>
      <div className={"flex justify-center"}>
        <NumberBoard
          code={codePin}
          setCode={setCodePin}
          nbElements={4}
          isSecret={true}
          isLoading={isLoading}
          error={errorCodePin}
          size={"MD"}
        />
      </div>
    </PageContainerParameters>
  );
};

export default Parameters;
