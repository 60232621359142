import React from "react";
import {
  EmptyStateCompanies,
  EmptyStateHourlyTracking,
} from "../../assets/icons";

const EmptyState = ({ type }) => {
  const logo = {
    companies: (
      <div className={"mx-auto my-2"}>
        <EmptyStateCompanies wh={64} />
      </div>
    ),
    hourly_tracking: (
      <div className={"mx-auto my-2"}>
        <EmptyStateHourlyTracking wh={120} />
      </div>
    ),
  };

  const title = {
    companies: "Aucun établissement ajouté",
    hourly_tracking: "Aucun intérimaire trouvé",
  };

  const description = {
    companies:
      "Vous devez ajouter une entreprise depuis votre espace web, pour pouvoir continuer.",
    hourly_tracking:
      "Aucun intérimaire n’a été trouvé pour ce jour. Vérifiez l’orthographe et assurez-vous qu’un contrat a bien été crée pour sa mission.",
  };

  const button = {
    companies: "",
  };

  return (
    <>
      <div className={"max-w-[390px] m-auto text-center p-3"}>
        <div className={"flex"}>{logo[type]}</div>
        <div className={"ft-xl font-bold text-gray-700"}>{title[type]}</div>
        <div className={"font-sm ft-b mt-2 text-gray-500"}>
          {description[type]}
        </div>
        <div className={"flex"}>
          <div className={"mx-auto"}>{button[type]}</div>
        </div>
      </div>
    </>
  );
};
export default EmptyState;
