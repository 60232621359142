import Modalheader from "../../../components/header";
import React, { useEffect, useState } from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import useModal from "../../../useModal";
import ModalBody from "../../../components/body";
import SelectPhone from "../../../../phone/SelectPhone";
import UseContext from "../../../../../hooks/useContext";
import ApiDatabase from "../../../../../server";

const ModalEditTempWorkerPhone = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn, setMissions } = UseContext();
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPhone(modalData.phone);
  }, [modalData]);

  const handleSubmit = () => {
    setIsLoading(true);
    if (phone === modalData.phone) {
      toggle();
      setIsLoading(false);
      return;
    }
    ApiDatabase.updateTempWorkerPhone(
      { idTempWorker: modalData.idTempWorker, phone },
      (data) => {
        toggle();
        setIsLoading(false);
        setModalDataReturn({ phone });
        setMissions((prevData) => {
          const newMissions = [...prevData];
          newMissions.forEach((mission) => {
            if (mission.user?.idTempWorker === modalData.idTempWorker) {
              mission.user.phone = phone;
            }
          });
          return newMissions;
        });
      },
      (err) => {
        if (
          err.message === "No internet connection - request queued" ||
          err.message ===
            "Request timed out - it has been queued for later execution" ||
          err.message === "No internet access or request timed out" ||
          err.message === "Network Error" ||
          err.message === "Failed to fetch" ||
          err.message === "Network request failed" ||
          err.message === "Connection refused" ||
          err.message === "Request aborted" ||
          err.message === "Internet Disconnected" ||
          err.message === "Unable to resolve the server's DNS address"
        ) {
          toggle();
          setIsLoading(false);
          setModalDataReturn({ phone });
          setMissions((prevData) => {
            const newMissions = [...prevData];
            newMissions.forEach((mission) => {
              if (mission.user?.idTempWorker === modalData.idTempWorker) {
                mission.user.phone = phone;
              }
            });
            return newMissions;
          });
        }
        setIsLoading(false);
        console.log(err);
      },
    );
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Modification téléphone
      </Modalheader>
      <ModalBody>
        <div className={"flex flex-col gap-2"}>
          <div className={"text-gray-500"}>
            Indiquez le nouveau numéro de téléphone de l'employé. Une fois que
            le numéro sera modifié, l'employé recevra un nouveau code PIN.
          </div>
          <label>
            <div className={"ft-sm font-medium text-gray-500"}>Numéro</div>
            <div className="relative">
              <SelectPhone phoneValue={phone} setPhoneValue={setPhone} />
            </div>
          </label>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button size={"LG"} color={"SECONDARY"} onClick={toggle}>
          Annuler
        </Button>
        {isLoading ? (
          <Button size={"LG"} color={"DISABLED"}>
            Confirmation...
          </Button>
        ) : (
          <Button size={"LG"} onClick={handleSubmit}>
            Confirmer
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalEditTempWorkerPhone;
