import PageContainer from "../components/pageContainer/PageContainer";
import StyledInput from "../components/styledInput/StyledInput";
import React, { useEffect, useState } from "react";
import useTitle from "../hooks/TitleManager";
import Button from "../components/button";
import { NavLink, useNavigate } from "react-router-dom";
import { validateEmail } from "../utils/Validator";
import ApiDatabase from "../server/index";
import { WarningCircle, WorkfullyLogo } from "../assets/icons";

const Login = () => {
  useTitle({ title: "Se connecter" });
  const navigate = useNavigate();

  const [isWaitingLoginComplete, setIsWaitingLoginComplete] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [errorRegister, setErrorRegister] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const token = localStorage.getItem("token");
    const badgerId = localStorage.getItem("badgerId");
    const companyId = localStorage.getItem("companyId");

    if (token && badgerId && companyId) {
      setIsWaitingLoginComplete(true);
      ApiDatabase.getUserLoginComplete(
        { useSpecialTimeout: true },
        (data) => {
          if (isMounted) {
            localStorage.setItem("badgerName", data.badgerName);
            localStorage.setItem("company", data.companyName);
            navigate("/app/badger");
          }
        },
        (err) => {
          if (isMounted) {
            console.log("err", err);
            setIsWaitingLoginComplete(false);
            localStorage.removeItem("companyId");
            localStorage.removeItem("badgerId");
            localStorage.removeItem("companyName");
            localStorage.removeItem("badgerName");
            localStorage.removeItem("token");
            localStorage.removeItem("company");
          }
        },
      );
    }

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  const handleSubmit = () => {
    if (email && password) {
      if (!validateEmail(email)) {
        setEmailError("Veuillez entrer une adresse e-mail valide.");
      } else {
        setEmailError(false);
        setLoading(true);
        ApiDatabase.postUserLogin(
          { email, password, rememberMe: true },
          (data) => {
            navigate("/app");
          },
          (err) => {
            if (
              err.response?.status === 400 &&
              (err.response?.data?.message === "Invalid password" ||
                err.response?.data?.message === "User not found")
            ) {
              setErrorRegister("Votre email ou mot de passe est incorrect.");
            }
            setLoading(false);
            console.log(err);
          },
        );
      }
    } else {
      if (!email) setEmailError("Veuillez entrer une adresse e-mail.");
      else setEmailError(false);
      if (!password) setPasswordError("Veuillez entrer un mot de passe.");
      else setPasswordError(false);
    }
  };

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div
          className={
            "w-full max-w-[448px] m-auto border rounded-xl bg-white p-8 flex flex-col gap-6 max-h-full"
          }
        >
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
          {isWaitingLoginComplete ? (
            <>
              <div className={"animate-pulse flex flex-col gap-2"}>
                <div className={"h-8 bg-gray-200 rounded w-64"}></div>
                <div className={"flex flex-col gap-1"}>
                  <div className={"h-3 bg-gray-200 rounded w-full"}></div>
                  <div className={"h-3 bg-gray-200 rounded w-32"}></div>
                </div>
              </div>
              <div className={"animate-pulse flex flex-col gap-2"}>
                <div className={"h-5 bg-gray-200 rounded w-16"}></div>
                <div className={"h-11 bg-gray-200 rounded w-full"}></div>
              </div>
              <div className={"animate-pulse flex flex-col gap-2"}>
                <div className={"h-5 bg-gray-200 rounded w-32"}></div>
                <div className={"h-11 bg-gray-200 rounded w-full"}></div>
              </div>
              <div className={"flex justify-end animate-pulse"}>
                <div className={"h-4 bg-gray-200 rounded w-56"}></div>
              </div>
              <div className={"animate-pulse"}>
                <div className={"h-14 bg-gray-200 rounded w-full"}></div>
              </div>
            </>
          ) : (
            <>
              <div className={""}>
                <div className={"text-3xl font-semibold"}>Se connecter</div>
                <div className={"text-sm text-gray-600"}>
                  Connectez-vous avec votre e-mail et votre mot de passe
                  Workflow.
                </div>
              </div>
              {errorRegister && (
                <div
                  className={
                    "w-full text-red-500 text-sm flex justify-center gap-1"
                  }
                >
                  <WarningCircle wh={20} color={"#EF4444"} />
                  {errorRegister}
                </div>
              )}
              <StyledInput
                type={"text"}
                label={"E-mail"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={emailError}
              />
              <StyledInput
                type={"password"}
                label={"Mot de passe"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                error={passwordError}
              />
              <div className={"flex justify-end"}>
                <NavLink
                  to={"/forgot-password"}
                  className={"text-workfully-700 underline"}
                >
                  Mot de passe oublié ?
                </NavLink>
              </div>
              {loading ? (
                <Button color={"DISABLED"} size={"FULL"}>
                  Connexion ...
                </Button>
              ) : (
                <Button color={"PRIMARY"} size={"FULL"} onClick={handleSubmit}>
                  Connexion
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default Login;
