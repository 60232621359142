import { useEffect, useRef, useState } from "react";
import {
  Check,
  ChevronDown,
  Import,
  NoShowPassword,
  ShowPassword,
  WarningCircle,
} from "../../assets/icons/index";

const StyledInput = ({
  label,
  type,
  unit,
  list,
  fileName,
  fileAccept,
  fileSize,
  placeholder,
  value,
  checked,
  onChange,
  error,
  imgFront,
}) => {
  const [typeInputChange, setTypeInputChange] = useState(type || "text");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const selectMultipleRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (
        selectMultipleRef.current &&
        !selectMultipleRef.current.contains(e.target)
      ) {
        setIsSelectOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [selectMultipleRef]);

  return (
    <>
      {(type === "text" ||
        type === "password" ||
        type === "number" ||
        type === "time") && (
        <label className={"flex flex-col gap-1"}>
          {label && <div className={"text-gray-500"}>{label}</div>}
          <div className={"relative"}>
            {imgFront ? (
              <div
                className={
                  "absolute left-0 top-1/2 transform -translate-y-1/2 p-3"
                }
              >
                {imgFront}
              </div>
            ) : null}
            <input
              type={typeInputChange}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              className={`w-full border rounded-md p-3 ${type === "password" ? "pr-7" : ""} ${type === "number" ? "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" : ""} ${unit ? "pr-20" : ""} ${imgFront ? "pl-9" : ""}`}
              {...(type === "number" && {
                pattern: "[0-9]*",
                inputMode: "numeric",
              })}
              {...(type === "email" && { inputMode: "email" })}
            />
            {type === "password" && (
              <>
                <div
                  className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                  onClick={() => {
                    if (typeInputChange === "password")
                      setTypeInputChange("text");
                    if (typeInputChange === "text")
                      setTypeInputChange("password");
                  }}
                >
                  {typeInputChange === "text" ? (
                    <NoShowPassword wh={18} color={"#666666"} strokeW={2} />
                  ) : (
                    <ShowPassword wh={18} color={"#666666"} strokeW={2} />
                  )}
                </div>
              </>
            )}
            {unit && (
              <div
                className={"absolute inset-y-0 right-0 flex items-center pr-3"}
              >
                {unit}
              </div>
            )}
          </div>
          {error && (
            <div className={"text-red-500 flex items-center gap-1 text-xs"}>
              <WarningCircle wh={16} color={"#EF4444"} />
              {error}
            </div>
          )}
        </label>
      )}
      {(type === "checkbox" || type === "checkbox-round") && (
        <label className={"flex gap-2 items-center relative cursor-pointer"}>
          <input
            type={"checkbox"}
            placeholder={placeholder}
            checked={checked}
            onChange={onChange}
            className={`${type === "checkbox-round" ? "w-5 h-5 border rounded appearance-none checked:bg-workfully-700 checked:border-transparent" : "w-4 h-4 accent-workfully-700"}`}
          />
          {type === "checkbox-round" && (
            <div
              className={"absolute inset-0 flex items-center justify-center"}
            >
              <Check wh={14} color={"#FFFFFF"} />
            </div>
          )}
          {label && <div className={""}>{label}</div>}
        </label>
      )}
      {type === "radio" && (
        <label className={"flex gap-2 items-center relative cursor-pointer"}>
          <input
            type={"radio"}
            placeholder={placeholder}
            checked={checked}
            onChange={onChange}
            className={`w-4 h-4 accent-workfully-700`}
          />
          {label && <div className={""}>{label}</div>}
        </label>
      )}
      {type === "file" && (
        <label className={"flex flex-col gap-1"}>
          <div className={"text-gray-500"}>{label}</div>
          <label className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
            <div className="ft-sm text-gray-700 p-4 flex items-center justify-center gap-2 border-r border-gray-300">
              <Import color={"#374151"} wh={14} />
              Importer
            </div>
            <div className="ft-sm truncate overflow-hidden text-gray-400 flex items-center ml-3">
              {fileName ? fileName : "Aucun fichier"}
            </div>
            <input
              type={type}
              onChange={onChange}
              accept={fileAccept.map((type) => type.name).join(", ")}
              className="hidden"
            />
          </label>
          <div className="ft-xs text-gray-400">
            {fileAccept.length > 1
              ? "Formats acceptés : "
              : "Format accepté : "}
            {fileAccept.map((type) => type.title).join(", ")} ({fileSize} max)
          </div>
          {error && (
            <div className={"text-red-500 flex items-center gap-1 text-xs"}>
              <WarningCircle wh={16} color={"#EF4444"} />
              {error}
            </div>
          )}
        </label>
      )}
      {type === "select" && (
        <label className={"flex flex-col gap-1"}>
          {label && <div className={"text-gray-500"}>{label}</div>}
          <select
            value={value}
            onChange={onChange}
            className="w-full border rounded-md px-3 py-2"
          >
            {list.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {error && (
            <div className={"text-red-500 flex items-center gap-1 text-xs"}>
              <WarningCircle wh={16} color={"#EF4444"} />
              {error}
            </div>
          )}
        </label>
      )}
      {type === "selectMultipleCheckbox" && (
        <div
          className={"flex flex-col gap-1 relative w-full cursor-pointer"}
          ref={selectMultipleRef}
        >
          {label && <div className={"text-gray-500"}>{label}</div>}
          <div
            onClick={() => setIsSelectOpen(!isSelectOpen)}
            onChange={onChange}
            className="w-full border rounded-md px-3 py-2"
          >
            <div className={"flex justify-between items-center gap-2 w-full"}>
              <div className={"truncate"}>{value}</div>
              <div className={"flex justify-end"}>
                <ChevronDown wh={20} color={"#374151"} />
              </div>
            </div>
          </div>
          {isSelectOpen && (
            <div
              className={
                "absolute top-full left-0 w-full bg-white border rounded shadow-2xl py-4 px-3 z-20 max-h-[160px] overflow-y-auto"
              }
            >
              <div className={"flex flex-col gap-4"}>
                {list.map((item, index) => (
                  <div key={index}>
                    <div className={"flex gap-1"}>
                      <StyledInput
                        label={item.label}
                        type={"checkbox"}
                        checked={item.checked}
                        onChange={(e) => {
                          onChange(
                            list.map((i, iIndex) =>
                              iIndex === index
                                ? { ...i, checked: e.target.checked }
                                : i,
                            ),
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {error && (
            <div className={"text-red-500 flex items-center gap-1 text-xs"}>
              <WarningCircle wh={16} color={"#EF4444"} />
              {error}
            </div>
          )}
        </div>
      )}
      {type === "selectMultipleRadio" && (
        <div
          className={"flex flex-col gap-1 relative w-full cursor-pointer"}
          ref={selectMultipleRef}
        >
          {label && <div className={"text-gray-500"}>{label}</div>}
          <div
            onClick={() => setIsSelectOpen(!isSelectOpen)}
            onChange={onChange}
            className="w-full border rounded-md px-3 py-2"
          >
            <div className={"flex justify-between items-center gap-2 w-full"}>
              <div className={"truncate"}>{value}</div>
              <div className={"flex justify-end"}>
                <ChevronDown wh={20} color={"#374151"} />
              </div>
            </div>
          </div>
          {isSelectOpen && (
            <div
              className={
                "absolute top-full left-0 w-full bg-white border rounded shadow-2xl py-4 px-3 z-20 max-h-[160px] overflow-y-auto"
              }
            >
              <div className={"flex flex-col gap-4"}>
                {list.map((item, index) => (
                  <div key={index}>
                    <div className={"flex gap-1"}>
                      <StyledInput
                        label={item.label}
                        type={"radio"}
                        checked={item.checked}
                        onChange={(e) => {
                          onChange(
                            list.map((i, iIndex) =>
                              iIndex === index
                                ? { ...i, checked: e.target.checked }
                                : { ...i, checked: false },
                            ),
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {error && (
            <div className={"text-red-500 flex items-center gap-1 text-xs"}>
              <WarningCircle wh={16} color={"#EF4444"} />
              {error}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StyledInput;
