import UseContext from "../../../hooks/useContext";
import ModalEditTempWorkerPhone from "./data/modalEditTempWorkerPhone/ModalEditTempWorkerPhone";
import ModalEditTempWorkerToken from "./data/modalEditTempWorkerToken/ModalEditTempWorkerToken";

const dataModal = new Map();
dataModal.set("edit_temp_worker_phone", <ModalEditTempWorkerPhone />);
dataModal.set("edit_temp_worker_token", <ModalEditTempWorkerToken />);

const Datamodal = () => {
  const { modalType } = UseContext();
  return <>{dataModal.get(modalType)}</>;
};

export default Datamodal;
