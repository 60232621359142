module.exports.floatToTime = (number, type = "h") => {
  // Check sign of given number
  let sign = number >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  number = number * sign;

  // Separate the int from the decimal part
  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = Math.floor(decpart * 60) + "";

  if (minute.length < 2) {
    minute = "0" + minute;
  }

  // Add Sign in final result
  sign = sign === 1 ? "" : "-";

  // Concate hours and minutes
  return sign + hour + type + (minute !== "00" ? minute : "");
};

module.exports.durationInDecimals = (start, end) => {
  if (!start || !end) return 0;
  const startTime = new Date(`1970-01-01T${start}`);
  const endTime = new Date(`1970-01-01T${end}`);
  return (endTime - startTime) / 3600000;
};

module.exports.calculateTotalHoursAndPause = (periods, timeCut) => {
  let totalPause = 0,
    totalHours = 0;

  for (let i = 0; i < periods.length; i++) {
    const nextStart = periods[i + 1]?.hours.start || "";
    const pause = this.durationInDecimals(periods[i].hours.end, nextStart);

    totalHours += this.durationInDecimals(
      periods[i].hours.start,
      periods[i].hours.end,
    );
    if (nextStart && pause <= timeCut) totalPause += pause;
  }

  return {
    pause: Math.round(totalPause * 100) / 100,
    totalHours: Math.round(totalHours * 100) / 100,
  };
};

module.exports.addMinute = (time) => {
  let [hours, minutes] = time.split(":").map(Number);
  minutes += 1;
  if (minutes === 60) {
    minutes = 0;
    hours += 1;
  }
  hours = hours < 10 ? `0${hours}` : hours.toString();
  minutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  return `${hours}:${minutes}`;
};
