const CACHE_DB_NAME = "ResponseCacheDB";
const CACHE_STORE_NAME = "responseCache";
const CACHE_EXPIRATION_MS = 5 * 60 * 1000; // 5 minutes

export async function addResponseToCache(request, response) {
  const db = await openDB();
  const tx = db.transaction(CACHE_STORE_NAME, "readwrite");
  const store = tx.objectStore(CACHE_STORE_NAME);
  await store.put({
    id: getRequestKey(request),
    response,
    timestamp: Date.now(),
  });
  await tx.complete;
}

export async function getResponseFromCache(request) {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const tx = db.transaction(CACHE_STORE_NAME, "readonly");
    const store = tx.objectStore(CACHE_STORE_NAME);
    const getRequest = store.get(getRequestKey(request));

    getRequest.onsuccess = () => {
      const cachedData = getRequest.result;

      if (
        cachedData &&
        Date.now() - cachedData.timestamp < CACHE_EXPIRATION_MS
      ) {
        resolve(cachedData.response);
      } else {
        resolve(null);
      }
    };
    getRequest.onerror = () => {
      reject(getRequest.error);
    };
  });
}

export async function clearExpiredCache() {
  const db = await openDB();
  const tx = db.transaction(CACHE_STORE_NAME, "readwrite");
  const store = tx.objectStore(CACHE_STORE_NAME);

  const now = Date.now();

  const allRequests = await new Promise((resolve, reject) => {
    const request = store.getAll();
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });

  const expiredRequests = allRequests.filter((request) => {
    return now - request.timestamp > CACHE_EXPIRATION_MS;
  });

  for (const request of expiredRequests) {
    await new Promise((resolve, reject) => {
      const deleteRequest = store.delete(request.id);
      deleteRequest.onsuccess = () => resolve();
      deleteRequest.onerror = () => reject(deleteRequest.error);
    });
  }

  await tx.complete;
}

function getRequestKey(request) {
  return `${request.method}_${request.url}_${JSON.stringify(request.data)}`;
}

function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(CACHE_DB_NAME, 1);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(CACHE_STORE_NAME)) {
        db.createObjectStore(CACHE_STORE_NAME, { keyPath: "id" });
      }
    };
    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
}
