import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import "./styles/main.css";
import * as Sentry from "@sentry/react";
import { requestPersistentStorage } from "./utils/persistentStorage";

requestPersistentStorage();

/*if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);

      // Gestion de la synchronisation en arrière-plan
      if ("sync" in registration) {
        navigator.serviceWorker.ready.then((reg) => {
          return reg.sync.register("sync-requests");
        });
      }
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}*/

if (process.env.REACT_APP_IS_PROD === "true") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    authToken: process.env.REACT_APP_SENTRY_AUTH_TOKEN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename={process.env.PUBLIC}>
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
);
