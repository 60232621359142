import { createContext, useEffect, useRef, useState } from "react";
import ApiDatabase from "../server";
import { dateShortHours, dateToEpoch } from "../utils/Date";
import {
  getResponseFromCache,
  addResponseToCache,
} from "../utils/responseCache";
import { clearExpiredCache } from "../utils/responseCache";
const roadContext = createContext({});

const ContextProvider = ({ children }) => {
  const [userData, setUserData] = useState({});

  const [dataCodePinConfirmUserBadger, setDataCodePinConfirmUserBadger] =
    useState({});

  const [allMissionToken, setAllMissionToken] = useState([]);

  const [isOnline, setIsOnline] = useState(true);

  const [userMission, setUserMission] = useState({});

  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalData, setModalData] = useState({});
  const [modalDataReturn, setModalDataReturn] = useState({});

  const [timeCut, setTimeCut] = useState(0);
  const [mission, setMission] = useState([]);

  const [missions, setMissions] = useState([]);

  const socketRef = useRef(null);

  const clearExpiredCacheIntervalRef = useRef(null);

  const [adminPinCode, setAdminPinCode] = useState("");

  useEffect(() => {
    const startInterval = () => {
      if (!clearExpiredCacheIntervalRef.current) {
        clearExpiredCacheIntervalRef.current = setInterval(
          async () => {
            if (isOnline) {
              await clearExpiredCache();
            }
          },
          5 * 60 * 1000,
        );
      }
    };

    const clearIntervalIfExists = () => {
      if (clearExpiredCacheIntervalRef.current) {
        clearInterval(clearExpiredCacheIntervalRef.current);
        clearExpiredCacheIntervalRef.current = null;
      }
    };

    if (isOnline) {
      startInterval();
    } else {
      clearIntervalIfExists();
    }

    return () => {
      clearIntervalIfExists();
    };
  }, [isOnline]);

  useEffect(() => {
    const manageSocket = async () => {
      socketRef.current =
        await ApiDatabase.renewTokenIfNeededAndConnectSocket();
      if (socketRef.current) {
        const handleTimeSheetChange = async (change, eventName) => {
          const cacheKeyMission = `get_mission/info/${change.idMission}?date=${new Date().toISOString().split("T")[0] + "T00:00:00.000Z"}_{"id":"${change.idMission}","date":"${new Date().toISOString().split("T")[0] + "T00:00:00.000Z"}"}`;
          const cachedDataMission = await getResponseFromCache({
            method: "GET",
            url: cacheKeyMission,
          });
          const cacheKeyMissions = `get_mission/list?idCompany=${change.companyId}&date=${new Date().toISOString().split("T")[0] + "T00:00:00.000Z"}_{"company":"${change.companyId}","date":"${new Date().toISOString().split("T")[0] + "T00:00:00.000Z"}"}`;
          const cachedDataMissions = await getResponseFromCache({
            method: "GET",
            url: cacheKeyMissions,
          });

          if (["create", "update"].includes(eventName)) {
            if (cachedDataMission && cachedDataMission.mission) {
              const periods = [...change.periods].sort(
                (a, b) => a.position - b.position,
              );
              cachedDataMission.mission = cachedDataMission.mission.map((m) => {
                if (
                  m.idTimeSheet === change.idTimeSheet ||
                  m.date === change.date
                ) {
                  return {
                    ...m,
                    markups: periods.map(({ hours }) => ({
                      start: hours.start,
                      end: hours.end,
                    })),
                    pause: change.pause || "",
                    totalHours: change.totalHours || "",
                    idTimeSheet: change._id,
                  };
                }
                return m;
              });
              await addResponseToCache(
                { method: "GET", url: cacheKeyMission },
                cachedDataMission,
              );
            }

            if (cachedDataMissions && cachedDataMissions.missions) {
              const periods = [...change.periods].sort(
                (a, b) => a.position - b.position,
              );
              cachedDataMissions.missions = cachedDataMissions.missions.map(
                (m) => {
                  if (
                    m.idMission === change.idMission &&
                    m.date === change.date
                  ) {
                    return {
                      ...m,
                      markup: {
                        start: periods[0]?.hours.start || "",
                        end: periods[periods.length - 1]?.hours.end || "",
                        pause: change.pause || "",
                      },
                      idTimeSheet: change._id,
                    };
                  }
                  return m;
                },
              );
              await addResponseToCache(
                { method: "GET", url: cacheKeyMissions },
                cachedDataMissions,
              );
            }

            setMission((currentMissions) => {
              const index = currentMissions.findIndex(
                (item) =>
                  item.idTimeSheet === change._id || item.date === change.date,
              );
              if (index === -1) return currentMissions;
              const updatedMissions = [...currentMissions];
              const periods = [...change.periods].sort(
                (a, b) => a.position - b.position,
              );
              updatedMissions[index] = {
                ...updatedMissions[index],
                markups: periods.map(({ hours }) => ({
                  start: hours.start,
                  end: hours.end,
                })),
                pause: change.pause || "",
                totalHours: change.totalHours || "",
                idTimeSheet: change._id,
              };
              return updatedMissions;
            });

            setMissions((currentMissions) =>
              currentMissions.map((m) => {
                if (
                  m.idMission === change.idMission &&
                  m.date === change.date
                ) {
                  const periods = [...change.periods].sort(
                    (a, b) => a.position - b.position,
                  );
                  return {
                    ...m,
                    markup: {
                      start: periods[0]?.hours.start || "",
                      end: periods[periods.length - 1]?.hours.end || "",
                      pause: change.pause || "",
                    },
                    idTimeSheet: change._id,
                  };
                }
                return m;
              }),
            );
          }

          if (eventName === "delete") {
            if (cachedDataMission && cachedDataMission.mission) {
              cachedDataMission.mission = cachedDataMission.mission.map((m) => {
                if (m.idTimeSheet === change._id) {
                  return {
                    ...m,
                    markups: [],
                    pause: "",
                    totalHours: "",
                    idTimeSheet: null,
                  };
                }
                return m;
              });
              await addResponseToCache(
                { method: "GET", url: cacheKeyMission },
                cachedDataMission,
              );
            }

            if (cachedDataMissions && cachedDataMissions.missions) {
              cachedDataMissions.missions = cachedDataMissions.missions.map(
                (m) => {
                  if (m.idTimeSheet === change._id) {
                    return {
                      ...m,
                      markup: { start: "", end: "", pause: "" },
                      idTimeSheet: null,
                    };
                  }
                  return m;
                },
              );
              await addResponseToCache(
                { method: "GET", url: cacheKeyMissions },
                cachedDataMissions,
              );
            }

            setMission((currentMissions) =>
              currentMissions.map((item) => {
                if (item.idTimeSheet === change._id) {
                  return {
                    ...item,
                    markups: [],
                    pause: "",
                    totalHours: "",
                    idTimeSheet: null,
                  };
                }
                return item;
              }),
            );

            setMissions((currentMissions) =>
              currentMissions.map((item) => {
                if (item.idTimeSheet === change._id) {
                  return {
                    ...item,
                    markup: { start: "", end: "", pause: "" },
                    idTimeSheet: null,
                  };
                }
                return item;
              }),
            );
          }

          if (change.temporaryWorker?.tokenMission) {
            const dateNow = dateShortHours(new Date());
            const dateNowSplit = dateNow.split("-");
            const dateNowYear = dateNowSplit[0];
            const dateNowMonth = dateNowSplit[1];
            const dateNowDay = dateNowSplit[2];

            const tokenMissionSearch = `post_mission/tokenMissionExist_{"tokenMission":${change.temporaryWorker.tokenMission},"date":"${dateNowYear}-${dateNowMonth}-${dateNowDay}"}`;
            const allKeys = Object.keys(localStorage);
            for (let key of allKeys) {
              if (key.startsWith(tokenMissionSearch)) {
                const cachedDataMission = await getResponseFromCache({
                  method: "GET",
                  url: key,
                });

                if (cachedDataMission) {
                  if (
                    cachedDataMission &&
                    cachedDataMission.timeSheet !== undefined
                  ) {
                    cachedDataMission.timeSheet = change.periods;
                    await addResponseToCache(
                      { method: "GET", url: key },
                      cachedDataMission,
                    );
                  }
                }
              }
            }

            setDataCodePinConfirmUserBadger((prevData) => ({
              ...prevData,
              timeSheet: change.periods,
            }));
          }
        };

        const handleTokenMissionsChange = async (change, type) => {
          const dateNow = dateShortHours(new Date());
          const dateNowSplit = dateNow.split("-");
          const dateNowYear = dateNowSplit[0];
          const dateNowMonth = dateNowSplit[1];
          const dateNowDay = dateNowSplit[2];

          const tokenMissionsSearch = `get_mission/tokenMissions?date=${dateNowYear}-${dateNowMonth}-${dateNowDay}`;
          const allKeys = Object.keys(localStorage);
          for (let key of allKeys) {
            if (key.startsWith(tokenMissionsSearch)) {
              const cachedDataMission = await getResponseFromCache({
                method: "GET",
                url: key,
              });

              if (cachedDataMission) {
                if (change._id) {
                  if (type === "create") {
                    cachedDataMission.push(change);
                    if (allMissionToken.length > 0) {
                      const allMissionToken = change.map((mission) => ({
                        _id: mission._id,
                        firstname: mission.firstname,
                        lastname: mission.lastname,
                        tokenMission: mission.tokenMission,
                        timeSheet: {
                          [dateToEpoch(new Date()).toISOString().split("T")[0]]:
                            mission.timeSheet,
                        },
                        timeCut: mission.timeCut,
                      }));
                      setAllMissionToken((prev) => [...prev, allMissionToken]);
                    }
                    await addResponseToCache(
                      { method: "GET", url: key },
                      cachedDataMission,
                    );
                  } else if (type === "change") {
                    let updatedDataMission = cachedDataMission.map(
                      (mission) => {
                        if (
                          mission?._id?.toString() === change?._id?.toString()
                        ) {
                          return {
                            _id: change._id,
                            firstname:
                              change.firstname || mission.firstname || "",
                            lastname: change.lastname || mission.lastname || "",
                            timeSheet:
                              change.timeSheet || mission.timeSheet || [],
                            timeCut: change.timeCut || mission.timeCut || "",
                            tokenMission:
                              change.tokenMission || mission.tokenMission || "",
                          };
                        }
                        return mission;
                      },
                    );

                    if (allMissionToken.length > 0) {
                      setAllMissionToken((prev) =>
                        prev.map((m) => {
                          if (change?._id?.toString() === m?._id?.toString()) {
                            return {
                              _id: change._id,
                              firstname: change.firstname || "",
                              lastname: change.lastname || "",
                              timeSheet: {
                                [dateToEpoch(new Date())
                                  .toISOString()
                                  .split("T")[0]]: change.timeSheet || [],
                              },
                              timeCut: change.timeCut || "",
                              tokenMission: change.tokenMission || "",
                            };
                          }
                          return m;
                        }),
                      );
                    }
                    await addResponseToCache(
                      { method: "GET", url: key },
                      updatedDataMission,
                    );
                  }
                }
              }
            }
          }
        };

        const handleBigCompanyChange = async (change, eventName) => {
          const cacheKeyBigCompanyTimeCut = `get_bigCompany/getTimeCut_{}`;
          const cachedDataBigCompanyTimeCut = await getResponseFromCache({
            method: "GET",
            url: cacheKeyBigCompanyTimeCut,
          });
          const cacheKeyUserCompany = `get_bigCompany/getTeams_{}`;
          const cachedDataUserCompany = await getResponseFromCache({
            method: "GET",
            url: cacheKeyUserCompany,
          });
          const cacheKeyNbInSettings = `get_bigCompany/getNbInSettings_{}`;
          const cachedDataNbInSettings = await getResponseFromCache({
            method: "GET",
            url: cacheKeyNbInSettings,
          });

          if (eventName === "update") {
            if (
              cachedDataBigCompanyTimeCut &&
              cachedDataBigCompanyTimeCut.timeCut
            ) {
              cachedDataBigCompanyTimeCut.timeCut = change.timeCut * 60;
              await addResponseToCache(
                { method: "GET", url: cacheKeyBigCompanyTimeCut },
                cachedDataBigCompanyTimeCut,
              );
            }
            if (cachedDataUserCompany && cachedDataUserCompany.teams) {
              cachedDataUserCompany.teams = change.teams;
              await addResponseToCache(
                { method: "GET", url: cacheKeyUserCompany },
                cachedDataUserCompany,
              );
            }
            if (cachedDataNbInSettings && cachedDataNbInSettings.nbCompanies) {
              cachedDataNbInSettings.nbCompanies = change.nbCompanies;
              await addResponseToCache(
                { method: "GET", url: cacheKeyNbInSettings },
                cachedDataNbInSettings,
              );
            }
            if (cachedDataNbInSettings && cachedDataNbInSettings.nbEmployees) {
              cachedDataNbInSettings.nbEmployees = change.employees.length;
              await addResponseToCache(
                { method: "GET", url: cacheKeyNbInSettings },
                cachedDataNbInSettings,
              );
            }
            setTimeCut(change.timeCut);
          }
        };

        const handleMissionChange = async (change, eventName) => {
          const cacheKeyMissions = `get_mission/list?idCompany=${change[0]?.companyId || change?.companyId}_{"company":"${change[0]?.companyId || change?.companyId}"}`;
          const cachedDataMissions = await getResponseFromCache({
            method: "GET",
            url: cacheKeyMissions,
          });

          if (eventName === "create") {
            if (cachedDataMissions && cachedDataMissions.missions) {
              change.forEach((c) => {
                if (
                  !cachedDataMissions.missions.find(
                    (m) => m._id === c._id && m.date === c.date,
                  )
                ) {
                  cachedDataMissions.missions.push(c);
                }
              });
              await addResponseToCache(
                { method: "GET", url: cacheKeyMissions },
                cachedDataMissions,
              );
            }
            setMissions((currentMissions) => {
              const newMissions = [...currentMissions];
              change.forEach((c) => {
                if (
                  !newMissions.find((m) => m._id === c._id && m.date === c.date)
                ) {
                  newMissions.push(c);
                }
              });
              return newMissions;
            });
          }
          if (eventName === "update") {
            if (cachedDataMissions && cachedDataMissions.missions) {
              cachedDataMissions.missions = cachedDataMissions.missions.map(
                (m) => {
                  const matchingChange = change.find(
                    (c) => m._id === c._id && m.date === c.date,
                  );
                  return matchingChange ? { ...m, ...matchingChange } : m;
                },
              );
              await addResponseToCache(
                { method: "GET", url: cacheKeyMissions },
                cachedDataMissions,
              );
            }

            setMissions((currentMissions) =>
              currentMissions.map((mission) => {
                const matchingChange = change.find(
                  (c) => mission._id === c._id && mission.date === c.date,
                );
                return matchingChange
                  ? { ...mission, ...matchingChange }
                  : mission;
              }),
            );
          }
          if (eventName === "delete") {
            if (cachedDataMissions && cachedDataMissions.missions) {
              cachedDataMissions.missions = cachedDataMissions.missions.filter(
                (m) => m._id !== change._id,
              );
              await addResponseToCache(
                { method: "GET", url: cacheKeyMissions },
                cachedDataMissions,
              );
            }
            setMissions((currentMissions) =>
              currentMissions.filter((m) => m._id !== change._id),
            );
          }
        };

        const handleUserMissionChange = async (change, eventName) => {
          const cacheKeyUserMission = `get_mission/user/${change[0]?.idMission}_{"id":"${change[0]?.idMission}"}`;
          const cachedDataUserMission = await getResponseFromCache({
            method: "GET",
            url: cacheKeyUserMission,
          });

          if (eventName === "update") {
            if (cachedDataUserMission && cachedDataUserMission.user) {
              cachedDataUserMission.user.phone = change[0].user.phone;
              await addResponseToCache(
                { method: "GET", url: cacheKeyUserMission },
                cachedDataUserMission,
              );
            }

            setUserMission((currentUserMission) => ({
              ...currentUserMission,
              phone: change[0].user.phone,
            }));
          }
        };

        socketRef.current.on("timeSheetChange", (change) =>
          handleTimeSheetChange(change, "update"),
        );
        socketRef.current.on("timeSheetCreate", (change) =>
          handleTimeSheetChange(change, "create"),
        );
        socketRef.current.on("timeSheetDelete", (change) =>
          handleTimeSheetChange(change, "delete"),
        );
        socketRef.current.on("tokenMissionCreate", (change) =>
          handleTokenMissionsChange(change, "create"),
        );
        socketRef.current.on("tokenMissionChange", (change) =>
          handleTokenMissionsChange(change, "change"),
        );
        socketRef.current.on("bigCompanyUpdate", (change) =>
          handleBigCompanyChange(change, "update"),
        );
        socketRef.current.on("missionChange", (change) => {
          handleMissionChange(change, "update");
          handleUserMissionChange(change, "update");
        });
      }
    };

    manageSocket();
  }, [allMissionToken.length]);

  return (
    <roadContext.Provider
      value={{
        userData,
        setUserData,
        dataCodePinConfirmUserBadger,
        setDataCodePinConfirmUserBadger,
        allMissionToken,
        setAllMissionToken,
        isOnline,
        setIsOnline,
        userMission,
        setUserMission,
        modalShow,
        setModalShow,
        modalType,
        setModalType,
        modalData,
        setModalData,
        modalDataReturn,
        setModalDataReturn,
        mission,
        setMission,
        missions,
        setMissions,
        timeCut,
        setTimeCut,
        adminPinCode,
        setAdminPinCode,
      }}
    >
      {children}
    </roadContext.Provider>
  );
};

export { ContextProvider, roadContext };
