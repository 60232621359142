import React, { useCallback, useEffect, useState } from "react";
import NumberBoard from "../components/numberBoard";
import { dateShortHours, dateShortText } from "../utils/Date";
import ApiDatabase from "../server";
import UseContext from "../hooks/useContext";
import { useNavigate } from "react-router-dom";
import PageContainerSiteBadge from "../components/pageContainer/PageContainerSiteBadge";
import useTitle from "../hooks/TitleManager";
import {
  addLogError,
  getAllLogErrors,
  getAllLogs,
  removeLog,
  removeLogError,
} from "../utils/indexedDB";

const Badger = () => {
  useTitle({ title: "Badgeuse" });
  const navigate = useNavigate();
  const {
    setDataCodePinConfirmUserBadger,
    allMissionToken,
    setAllMissionToken,
    setMissions,
    setTimeCut,
    setAdminPinCode,
  } = UseContext();
  const [codePin, setCodePin] = useState("");
  const [errorCodePin, setErrorCodePin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState({});
  const companySelected = localStorage.getItem("companyId");

  useEffect(() => {
    if (date.dateTextHours) {
      console.log("date.dateTextHours", date.dateTextHours);
      ApiDatabase.getTokenMissions(
        { date: date.dateTextHours, useSpecialTimeout: true },
        (data) => {
          const allMissionToken = data.map((mission) => ({
            _id: mission._id,
            firstname: mission.firstname,
            lastname: mission.lastname,
            tokenMission: mission.tokenMission,
            timeSheet: {
              [date.dateTextHours.split(" ")[0]]: mission.timeSheet,
            },
            timeCut: mission.timeCut,
          }));
          setAllMissionToken(allMissionToken);
        },
        (err) => {
          console.log("err", err);
        },
      );
    }
  }, [date.dateTextHours, setAllMissionToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allLogErrors = await getAllLogErrors();
        const allLog = await getAllLogs();

        await ApiDatabase.postLogAddHour(
          {
            logAddHour: allLog,
            logAddHourError: allLogErrors,
            useSpecialTimeout: true,
          },
          async (data) => {
            const removeOldLogs = async (logs, removeFn) => {
              for (const log of logs) {
                await removeFn(log.id);
              }
            };

            await Promise.all([
              removeOldLogs(allLog, removeLog),
              removeOldLogs(allLogErrors, removeLogError),
            ]);
            console.log("data", data);
          },
          (err) => {
            console.log("err", err);
          },
        );
      } catch (error) {
        console.error("An error occurred while fetching logs:", error);
      }
    };

    fetchData();

    return () => {};
  }, [date.dateTextHours]);

  useEffect(() => {
    if (companySelected) {
      ApiDatabase.getMissions(
        {
          company: companySelected,
          date: new Date().toISOString().split("T")[0] + "T00:00:00.000Z",
          useSpecialTimeout: true,
        },
        (data) => {
          setMissions(data.missions || []);
          setTimeCut(data.timeCut);
        },
        (err) => {
          console.log("err", err);
        },
      );
    } else {
      setMissions([]);
    }
  }, [companySelected, setMissions, setTimeCut]);

  useEffect(() => {
    ApiDatabase.getAdminPinCode(
      { useSpecialTimeout: true },
      (data) => {
        setAdminPinCode(data.adminPinCode);
      },
      (err) => {
        console.log("err", err);
      },
    );
  }, [setAdminPinCode]);

  const dateManage = () => {
    const dateNow = new Date();
    const dateShort = dateShortText(dateNow);
    //add 0 if needed
    const dateHours = `${dateNow.getHours() < 10 ? "0" : ""}${dateNow.getHours()}:${dateNow.getMinutes() < 10 ? "0" : ""}${dateNow.getMinutes()}`;
    const dateTextHours = dateShortHours(dateNow);
    setDate({
      dateText: dateShort,
      hoursText: dateHours,
      dateTextHours: dateTextHours,
    });
  };

  useEffect(() => {
    dateManage();

    const intervalId = setInterval(() => {
      dateManage();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const postCodePinBadger = useCallback(() => {
    if (codePin.length === 4 && date.dateTextHours && !isLoading) {
      setIsLoading(true);
      const tokenMissionExist = allMissionToken?.find(
        (mission) => mission.tokenMission === codePin,
      );
      console.log("tokenMissionExist", tokenMissionExist);
      if (tokenMissionExist) {
        setDataCodePinConfirmUserBadger({
          firstname: tokenMissionExist.firstname,
          lastname: tokenMissionExist.lastname,
          timeSheet: tokenMissionExist.timeSheet,
          timeCut: tokenMissionExist.timeCut,
          tokenMission: codePin,
          date: date.dateTextHours,
        });
        navigate("/app/confirm-user-badger");
      } else {
        addLogError({
          tokenMission: codePin,
          date: date.dateTextHours,
          action: "postCodePinBadgerError",
          allMissionToken,
        })
          .then(() => {
            setErrorCodePin(true);
            setIsLoading(false);
            setCodePin("");
          })
          .catch((err) => {
            console.error("Error adding log:", err);
          });
      }
    }
  }, [
    codePin,
    date.dateTextHours,
    isLoading,
    navigate,
    setDataCodePinConfirmUserBadger,
    allMissionToken,
  ]);

  useEffect(() => {
    postCodePinBadger();
  }, [postCodePinBadger]);

  useEffect(() => {
    if (codePin.length > 0 && errorCodePin) {
      setErrorCodePin(false);
    }
  }, [codePin, errorCodePin]);

  return (
    <PageContainerSiteBadge>
      <div className={"flex justify-center"}>
        <NumberBoard
          code={codePin}
          setCode={setCodePin}
          nbElements={4}
          isSecret={true}
          isLoading={isLoading}
          error={errorCodePin}
          size={"LG"}
        />
      </div>
    </PageContainerSiteBadge>
  );
};

export default Badger;
