import { ArrowLeft, WorkfullyLogoWhite } from "../../assets/icons";
import React from "react";

const PageContainerParameters = ({ isPageFull, children }) => {
  const companyName = localStorage.getItem("company");
  const badgerName = localStorage.getItem("badgerName");

  return (
    <div className="flex flex-col h-screen select-none">
      <div className={"flex-1 h-full"}>
        <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
          <div
            className={`flex flex-col gap-8 w-full max-w-[448px] m-auto ${isPageFull && "h-full max-h-[658px]"}`}
          >
            <div className={"flex justify-between items-center"}>
              <div className={"flex flex-col"}>
                <div className={"capitalize text-white text-sm"}>
                  Espace Administrateur
                </div>
                <div className={"text-white font-bold"}>
                  Entrez votre code PIN
                </div>
              </div>
              <div className={"flex"}>
                <div className={"flex flex-col text-right"}>
                  <div className={"text-white font-bold"}>{companyName}</div>
                  <div className={"text-white"}>{badgerName}</div>
                </div>
              </div>
            </div>
            <div
              className={`border rounded-xl bg-white p-8 flex flex-col gap-6 ${isPageFull ? "h-full" : "max-h-full"} relative`}
            >
              {children}
              <div className={"absolute -left-24 top-0"}>
                <div
                  className={
                    "w-[70px] h-[70px] flex justify-center items-center border rounded-full bg-white cursor-pointer"
                  }
                  onClick={() => window.history.back()}
                >
                  <ArrowLeft wh={34} color={"#374151"} />
                </div>
              </div>
            </div>
            <div className={"mx-auto"}>
              <WorkfullyLogoWhite w={130} h={30} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContainerParameters;
