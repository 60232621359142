module.exports.reorderPeriods = (periods) => {
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const swapTimes = (periodA, periodB, keyA, keyB) => {
    [periodA.hours[keyA], periodB.hours[keyB]] = [
      periodB.hours[keyB],
      periodA.hours[keyA],
    ];
    [
      periodA.hours[`id${keyA.charAt(0).toUpperCase() + keyA.slice(1)}`],
      periodB.hours[`id${keyB.charAt(0).toUpperCase() + keyB.slice(1)}`],
    ] = [
      periodB.hours[`id${keyB.charAt(0).toUpperCase() + keyB.slice(1)}`],
      periodA.hours[`id${keyA.charAt(0).toUpperCase() + keyA.slice(1)}`],
    ];
  };

  for (let h = 0; h < periods.length * 2; h++) {
    for (let i = 0; i < periods.length; i++) {
      if (
        timeToMinutes(periods[i].hours.start) >
        timeToMinutes(periods[i].hours.end)
      ) {
        swapTimes(periods[i], periods[i], "start", "end");
      }
      if (
        periods[i + 1]?.hours?.start &&
        timeToMinutes(periods[i].hours.end) >
          timeToMinutes(periods[i + 1].hours.start)
      ) {
        swapTimes(periods[i], periods[i + 1], "end", "start");
      }
    }
  }

  periods.forEach((period, index) => (period.position = index + 1));

  return periods;
};
