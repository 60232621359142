import Pagination from "../pagination/Pagination";

const FooterHourlyTracking = ({
  currentPage,
  countPerPage,
  countTotal,
  totalPages,
  setCurrentPage,
}) => {
  return (
    <div className={"w-full flex justify-end items-center"}>
      <div className={""}>
        {currentPage && totalPages > 1 && setCurrentPage ? (
          <Pagination
            currentPage={currentPage}
            countPerPage={countPerPage}
            countTotal={countTotal}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FooterHourlyTracking;
