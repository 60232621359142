import React, { useEffect } from "react";
import { Check } from "../assets/icons";
import { useNavigate } from "react-router-dom";
import useTitle from "../hooks/TitleManager";
import PageContainerSiteBadge from "../components/pageContainer/PageContainerSiteBadge";

const SuccessUserBadger = () => {
  useTitle({ title: "Horaires" });
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (window.location.pathname === "/app/success-user-badger") {
        navigate("/app/badger");
      }
    }, 3000);
  }, [navigate]);

  return (
    <PageContainerSiteBadge>
      <div className={"flex justify-center"}>
        <div
          className={
            "rounded-full border w-28 h-28 flex justify-center items-center"
          }
        >
          <Check wh={55} color={"#374151"} />
        </div>
      </div>
      <div className={"text-4xl font-black text-center"}>Tout est bon.</div>
      <div className={"text-2xl text-center text-gray-700"}>
        Bon succès pour aujourd’hui, n’oubliez pas de badgez à vos pauses et à
        votre sortie de l’établissement.
      </div>
    </PageContainerSiteBadge>
  );
};

export default SuccessUserBadger;
