import NavbarSiteParameters from "../navbars/NavbarSiteParameters";
import SidebarParameters from "../sidebars/SidebarParameters";
import React, { useEffect } from "react";
import UseContext from "../../hooks/useContext";
import ApiDatabase from "../../server";

const PageContainerParametersDetail = ({
  type,
  search,
  setSearch,
  name,
  children,
}) => {
  const { isOnline, setIsOnline } = UseContext();

  useEffect(() => {
    const handleNetworkChange = async () => {
      setIsOnline(await ApiDatabase.checkConnection());
    };

    handleNetworkChange();

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    const intervalId = setInterval(handleNetworkChange, 300000);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
      clearInterval(intervalId);
    };
  }, [setIsOnline]);

  return (
    <div className="flex flex-col h-screen select-none">
      <div className={"flex flex-col flex-1 max-h-full"}>
        {!isOnline && (
          <div className={"bg-red-200 text-red-900 text-center p-1"}>
            Vous êtes hors ligne. Connectez-vous au wifi pour consulter les
            pointages et modifier les numéros de téléphone.
          </div>
        )}
        <div className={"flex h-full overflow-auto"}>
          <SidebarParameters />
          <div className={"flex flex-col w-full"}>
            {type === "hourly-tracking" && (
              <NavbarSiteParameters search={search} setSearch={setSearch} />
            )}
            <div className={"flex-1 px-8 py-6 overflow-auto"}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContainerParametersDetail;
