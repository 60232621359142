import { openDB } from "idb";

const dbName = "WFDB";
const requestQueueStoreName = "requestQueue";
const logStoreName = "logAddHour";
const logErrorStoreName = "logAddHourError";
const dbVersion = 3;

const initDB = async () => {
  return openDB(dbName, dbVersion, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(requestQueueStoreName)) {
        db.createObjectStore(requestQueueStoreName, {
          keyPath: "id",
          autoIncrement: true,
        });
      }

      if (!db.objectStoreNames.contains(logStoreName)) {
        db.createObjectStore(logStoreName, {
          keyPath: "id",
          autoIncrement: true,
        });
      }

      if (!db.objectStoreNames.contains(logErrorStoreName)) {
        db.createObjectStore(logErrorStoreName, {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    },
  });
};

export const addRequest = async (request) => {
  const db = await initDB();
  const tx = db.transaction(requestQueueStoreName, "readwrite");
  await tx.store.add(request);
  await tx.done;
};

export const getAllRequests = async () => {
  const db = await initDB();
  return db.getAll(requestQueueStoreName);
};

export const clearRequests = async () => {
  const db = await initDB();
  const tx = db.transaction(requestQueueStoreName, "readwrite");
  await tx.store.clear();
  await tx.done;
};

export const removeRequest = async (id) => {
  const db = await initDB();
  const tx = db.transaction(requestQueueStoreName, "readwrite");
  await tx.store.delete(id);
  await tx.done;
};

export const addLog = async (log) => {
  const db = await initDB();
  const tx = db.transaction(logStoreName, "readwrite");
  await tx.store.add(log);
  await tx.done;
};

export const getAllLogs = async () => {
  const db = await initDB();
  return await db.getAll(logStoreName);
};

export const clearLogs = async () => {
  const db = await initDB();
  const tx = db.transaction(logStoreName, "readwrite");
  await tx.store.clear();
  await tx.done;
};

export const removeLog = async (id) => {
  const db = await initDB();
  const tx = db.transaction(logStoreName, "readwrite");
  await tx.store.delete(id);
  await tx.done;
};

export const addLogError = async (errorLog) => {
  const db = await initDB();
  const tx = db.transaction(logErrorStoreName, "readwrite");
  await tx.store.add(errorLog);
  await tx.done;
};

export const getAllLogErrors = async () => {
  const db = await initDB();
  return await db.getAll(logErrorStoreName);
};

export const clearLogErrors = async () => {
  const db = await initDB();
  const tx = db.transaction(logErrorStoreName, "readwrite");
  await tx.store.clear();
  await tx.done;
};

export const removeLogError = async (id) => {
  const db = await initDB();
  const tx = db.transaction(logErrorStoreName, "readwrite");
  await tx.store.delete(id);
  await tx.done;
};

initDB().then(() => console.log("Database initialized"));
