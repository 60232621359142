import PageContainer from "../components/pageContainer/PageContainer";
import { Check, WorkfullyLogo } from "../assets/icons";
import React, { useEffect, useState } from "react";
import ApiDatabase from "../server";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import EmptyState from "../components/EmptyState/EmptyState";
import useTitle from "../hooks/TitleManager";

const Companies = () => {
  useTitle({ title: "Choix entreprises" });
  const navigate = useNavigate();

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isWaiting, setIsWaiting] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsWaiting(true);
    ApiDatabase.getCompanies(
      {},
      (data) => {
        setCompanies(data.companies);
        setIsWaiting(false);
      },
      (err) => {
        console.log(err);
        setIsWaiting(false);
      },
    );
  }, []);

  const selectCompany = () => {
    if (selectedCompany) {
      setIsLoading(true);

      ApiDatabase.postBadgerCompany(
        { idCompany: selectedCompany },
        (data) => {
          setIsLoading(false);
          if (data.isAdminPinCode) {
            localStorage.setItem(
              "company",
              companies.find((c) => c._id === selectedCompany).name,
            );
            localStorage.setItem("companyId", selectedCompany);

            navigate("/app/badger-name");
          } else {
            localStorage.setItem(
              "company",
              companies.find((c) => c._id === selectedCompany).name,
            );
            localStorage.setItem("companyId", selectedCompany);
            navigate("/app/first-pin-admin");
          }
        },
        (err) => {
          setIsLoading(false);
          console.log(err);
        },
      );
    }
  };

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div
          className={
            "w-full max-w-[448px] m-auto border rounded-xl bg-white p-8 flex flex-col gap-6 max-h-full"
          }
        >
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
          <div className={""}>
            <div className={"text-3xl font-semibold"}>
              Sélectionner l'établissement
            </div>
            <div className={"text-sm text-gray-600"}>
              Indiquez l’identifiant correspondant à votre établissement pour
              activer la badgeuse.
            </div>
          </div>
          <div className={"flex flex-col gap-2 overflow-y-auto"}>
            {isWaiting ? (
              [1, 2, 3].map((index) => (
                <div
                  key={index}
                  className={"border rounded-lg p-3 w-full animate-pulse"}
                >
                  <div className={"h-6 bg-gray-200 rounded w-32"}></div>
                </div>
              ))
            ) : (
              <>
                {companies.length === 0 ? (
                  <div className={"my-2"}>
                    <EmptyState type={"companies"} />
                  </div>
                ) : (
                  companies.map((company, index) => (
                    <div
                      key={index}
                      className={
                        "border rounded-lg p-3 w-full cursor-pointer flex justify-between items-center"
                      }
                      onClick={() => setSelectedCompany(company._id)}
                    >
                      <div>{company.name}</div>
                      {selectedCompany === company._id && (
                        <div
                          className={
                            "w-4 h-4 rounded-full bg-workfully-700 flex justify-center items-center"
                          }
                        >
                          <Check wh={14} color={"#FFFFFF"} />
                        </div>
                      )}
                    </div>
                  ))
                )}
              </>
            )}
          </div>
          <div className={"flex gap-4"}>
            <Button
              color={"SECONDARY"}
              size={"FULL"}
              onClick={() => navigate("/")}
            >
              Annuler
            </Button>
            {companies.length > 0 && (
              <>
                {selectedCompany ? (
                  <>
                    {isLoading ? (
                      <Button color={"DISABLED"} size={"FULL"}>
                        Confirmation...
                      </Button>
                    ) : (
                      <Button
                        color={"PRIMARY"}
                        size={"FULL"}
                        onClick={selectCompany}
                      >
                        Confirmer
                      </Button>
                    )}
                  </>
                ) : (
                  <Button color={"DISABLED"} size={"FULL"}>
                    Confirmer
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Companies;
