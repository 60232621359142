import React, { useEffect, useState } from "react";
import { ContextProvider } from "./context/Context";
import Routes from "./routes";
import { useBattery } from "react-use";
import Modal from "./components/modal/portal";
import Button from "./components/button";
import { WorkfullyLogo, WorkfullyLogoMini } from "./assets/icons";

let deferredPrompt;

const App = () => {
  const battery = useBattery();
  const [installable, setInstallable] = useState(false);
  const [isStandalone, setIsStandalone] = useState(
    window.matchMedia("(display-mode: standalone)").matches,
  );

  useEffect(() => {
    const checkStandaloneMode = () => {
      const standalone = window.matchMedia(
        "(display-mode: standalone)",
      ).matches;
      if (standalone !== isStandalone) {
        setIsStandalone(standalone);
        window.location.reload();
      }
    };

    const intervalId = setInterval(checkStandaloneMode, 1000);

    return () => clearInterval(intervalId);
  }, [isStandalone]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      console.log("INSTALL: Success");
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", () => {});
      window.removeEventListener("appinstalled", () => {});
    };
  }, []);

  useEffect(() => {
    const updateBatteryData = () => {
      const { isSupported, level, charging } = battery;

      if (
        isSupported &&
        typeof level === "number" &&
        typeof charging === "boolean"
      ) {
        const batteryInfo = {
          level: parseFloat((level * 100).toFixed(0)),
          charging,
        };

        localStorage.setItem("battery", JSON.stringify(batteryInfo));
      }
    };

    updateBatteryData();

    const intervalId = setInterval(updateBatteryData, 60000);

    return () => clearInterval(intervalId);
  }, [battery]);

  const handleInstallClick = () => {
    setInstallable(false);
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  };

  const isProduction = process.env.REACT_APP_IS_PROD === "true";

  if (isProduction && !isStandalone) {
    return (
      <div className={"h-screen flex justify-center items-center select-none"}>
        <div className={"flex flex-col items-center gap-24"}>
          <div
            className={
              "flex flex-col items-center gap-8 text-center max-w-[454px]"
            }
          >
            <div
              className={
                "w-[90px] h-[90px] flex justify-center items-center bg-workfully-400 rounded-xl"
              }
            >
              <WorkfullyLogoMini wh={60} />
            </div>
            <div className={"flex flex-col gap-2"}>
              <div className={"text-3xl font-black"}>Accédez à WorkFully</div>
              <div className={"text-gray-700"}>
                Pour une meilleure expérience utilisateur, veuillez utiliser
                notre application Workfully.
              </div>
            </div>
            {installable ? (
              <Button
                color={"PRIMARY"}
                size={"LG"}
                onClick={handleInstallClick}
              >
                Télécharger l'application
              </Button>
            ) : (
              "Vous retrouverez l'application WorkFully sur votre écran d'accueil"
            )}
          </div>
          <WorkfullyLogo w={130} h={30} />
        </div>
      </div>
    );
  }

  return (
    <ContextProvider>
      <Modal />
      <Routes />
    </ContextProvider>
  );
};

export default App;
