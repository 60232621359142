import {
  ChevronRight,
  InformationCircle,
  PhoneIcon,
  WarningCircle,
} from "../../assets/icons";
import Badges from "../badges";
import { useEffect, useState } from "react";
import { floatToTime } from "../../utils/Time";

const TableHourTrackingMission = ({
  mission,
  navigateToMission,
  isWaiting = false,
}) => {
  const [isDateBeforeDateNow, setIsDateBeforeDateNow] = useState(false);
  const [isDateAfterDateNow, setIsDateAfterDateNow] = useState(false);

  useEffect(() => {
    if (!isWaiting) {
      const dateNow = new Date(new Date().toISOString().split("T")[0]);
      const dateMission = new Date(
        new Date(mission.date).toISOString().split("T")[0],
      );

      //compare date year month day
      setIsDateBeforeDateNow(
        new Date(dateMission).getTime() < new Date(dateNow).getTime(),
      );
      setIsDateAfterDateNow(
        new Date(dateMission).getTime() > new Date(dateNow).getTime(),
      );
    }
  }, [mission, isWaiting]);

  return (
    <>
      {isWaiting ? (
        <div
          className={`grid grid-cols-5 border rounded-lg gap-8 px-4 py-3 text-sm`}
        >
          <div className={"flex flex-col justify-center"}>
            <div className={"h-4 bg-gray-200 rounded w-40"}></div>
          </div>
          <div className={"flex flex-col justify-center gap-1"}>
            <div className={"h-4 bg-gray-200 rounded w-20"}></div>
            <div className={"h-4 bg-gray-200 rounded w-40"}></div>
          </div>
          <div className={"flex flex-col justify-center gap-1"}>
            <div className={"h-4 bg-gray-200 rounded w-20"}></div>
            <div className={"h-4 bg-gray-200 rounded w-40"}></div>
          </div>
          <div className={"flex flex-col justify-center gap-1"}>
            <div className={"h-4 bg-gray-200 rounded w-20"}></div>
            <div className={"h-4 bg-gray-200 rounded w-40"}></div>
          </div>
          <div className={"flex justify-end items-center gap-2"}>
            <div className={"h-6 bg-gray-200 rounded w-20"}></div>
            <div className={"h-6 bg-gray-200 rounded w-6"}></div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => navigateToMission(mission.idMission)}
          className={`grid grid-cols-5 border rounded-lg cursor-pointer gap-8 px-4 py-3 text-sm ${
            !mission.markup.start &&
            !mission.markup.end &&
            !isDateAfterDateNow &&
            isDateBeforeDateNow
              ? "bg-gray-50"
              : ""
          }`}
        >
          <div className={"flex flex-col justify-center"}>
            <div className={"truncate"}>
              {mission.user.lastname} {mission.user.firstname}
            </div>
          </div>
          <div className={"flex flex-col justify-center"}>
            <div>Mission</div>
            <div className={"text-gray-500 truncate"}>{mission.idMission}</div>
          </div>
          <div className={"flex flex-col justify-center"}>
            <div>Établissement</div>
            <div className={"text-gray-500 truncate"}>
              {mission.companyName}
            </div>
          </div>
          <div className={"flex flex-col justify-center"}>
            {mission?.markup?.start && (
              <>
                <div>Émargé</div>
                <div className={"text-gray-500"}>
                  {mission?.markup?.start} > {mission?.markup?.end || "-"}
                  {mission?.markup?.pause &&
                    ` (${floatToTime(mission?.markup?.pause, "'")})`}
                </div>
              </>
            )}
          </div>
          <div className={"flex justify-end items-center"}>
            <div className={"flex items-center gap-3"}>
              {mission.isFlexibilityDate && (
                <Badges color={"PRIMARY"}>
                  <div className={"flex gap-1"}>
                    Souplesse
                    <InformationCircle wh={16} color={"#374151"} />
                  </div>
                </Badges>
              )}
              {!mission.user.phone && <PhoneIcon wh={20} color={"#EF4444"} />}
              <div className={"text-nowrap"}>
                {mission.markup.start &&
                  mission.markup.end &&
                  !isDateAfterDateNow && (
                    <>
                      {isDateBeforeDateNow ? (
                        <Badges color={"INFO"}>Shift terminé</Badges>
                      ) : (
                        <Badges color={"SUCCESS"}>En cours</Badges>
                      )}
                    </>
                  )}
                {mission.markup.start &&
                  !mission.markup.end &&
                  !isDateBeforeDateNow &&
                  !isDateAfterDateNow && (
                    <Badges color={"SUCCESS"}>En cours</Badges>
                  )}
                {mission.markup.start &&
                  !mission.markup.end &&
                  isDateBeforeDateNow &&
                  !isDateAfterDateNow && (
                    <Badges color={"ALERT"}>
                      <div className={"flex gap-1 items-center"}>
                        <div>
                          <WarningCircle wh={16} color={"#EF4444"} />{" "}
                        </div>
                        <div>Manquant</div>
                      </div>
                    </Badges>
                  )}
                {!mission.markup.start &&
                  !mission.markup.end &&
                  !isDateAfterDateNow && (
                    <Badges color={"SECONDARY"}>Non émargé</Badges>
                  )}
              </div>
              <ChevronRight wh={20} color={"#6B7280"} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableHourTrackingMission;
