import useTitle from "../hooks/TitleManager";

const PageNotFound = () => {
  useTitle({ title: "Page introuvable" });
  return (
    <div className={""}>
      <h1>404 Page not found</h1>
    </div>
  );
};

export default PageNotFound;
