import { Route, Routes } from "react-router-dom";
import Layout from "../pages/Layout";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import PageNotFound from "../pages/PageNotFound";
import ForgotPasswordChange from "../pages/ForgotPasswordChange";
import Invitation from "../pages/Invitation";
import Companies from "../pages/Companies";
import Badger from "../pages/Badger";
import FirstPinAdmin from "../pages/FirstPinAdmin";
import ConfirmUserBadger from "../pages/ConfirmUserBadger";
import SuccessUserBadger from "../pages/SuccessUserBadger";
import Parameters from "../pages/Parameters";
import HourlyTracking from "../pages/HourlyTracking";
import Mission from "../pages/Mission";
import BadgerName from "../pages/BadgerName";

const ConnectionRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={""} element={<Login />} />
        <Route path={"forgot-password"} element={<ForgotPassword />} />
        <Route
          path={"forgot-password-change"}
          element={<ForgotPasswordChange />}
        />
        <Route path={"invitation/:id"} element={<Invitation />} />
        <Route path={"*"} element={<PageNotFound />} />

        <Route path="/app" element={<Layout />}>
          <Route path={"/app/companies"} element={<Companies />} />
          <Route path={"/app/badger-name"} element={<BadgerName />} />
          <Route path={"/app/first-pin-admin"} element={<FirstPinAdmin />} />
          <Route path={"/app/badger"} element={<Badger />} />
          <Route
            path={"/app/confirm-user-badger"}
            element={<ConfirmUserBadger />}
          />
          <Route
            path={"/app/success-user-badger"}
            element={<SuccessUserBadger />}
          />
          <Route path={"/app/parameters"} element={<Parameters />} />
          <Route
            path={"/app/parameters/hourly-tracking"}
            element={<HourlyTracking />}
          />
          <Route
            path={"/app/parameters/hourly-tracking/:id"}
            element={<Mission />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default ConnectionRoutes;
